import React, { useEffect, useMemo, useState } from "react";
import { Button, Dialog, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddressFields from "../Setting/AddressFields";
import {
  getLatestAddress,
  isObjectPartiallyFilled,
  removeEmptyValues,
  validateEmail,
  validatePhoneNumber,
} from "../../utils";
import InputMask from "react-input-mask";
import { ADDRESS_TYPE_ENUM, COUNTRIES } from "../../constants";
import { updateRequest } from "../../api";
import { addToast } from "../../redux/toastSlice";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/loadingSlice";

const INITIAL_STATE = {
  name: "",
  email: "",
  phone_number: "",
  country: COUNTRIES[0]?.value,
  line: "",
  city: "",
  state: "",
  zipcode: "",
  cName: "",
  cPhoneNumber: "",
  cEmail: "",
};

const UpdateCustomerDetails = ({
  open,
  close,
  isCorporateCustomer,
  isIndividualDetail = false,
  user,
  currentStore,
  fetchOrder,
  setUser
}) => {
  const [formState, setFormState] = useState();
  const [originalState, setOriginalState] = useState(INITIAL_STATE);
  const dispatch = useDispatch();

  const handleClose = () => {
    close();
    setFormState(INITIAL_STATE);
    setOriginalState(INITIAL_STATE);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(setLoading(true));
      const payload = {
        user: {
          ...(isCorporateCustomer && !isIndividualDetail
            ? {
                contacts_attributes: [
                  {
                    id: formState?.cId,
                    name: formState?.cName,
                    email: formState?.cEmail,
                    phone_number: formState?.cPhoneNumber,
                  },
                ],
              }
            : {
                email: formState?.email,
                phone_number: formState?.phone_number,
              }),
          ...(!isIndividualDetail && {
            addresses_attributes: [
              {
                id: formState?.addressId,
                line: formState?.line,
                zipcode: formState?.zipcode,
                city: formState?.city,
                state: formState?.state,
                country: formState?.country,
                address_type: ADDRESS_TYPE_ENUM.shipping
              },
            ],
          }),
          name: formState?.name,
        },
      };

      const response = await updateRequest(
        `stores/${currentStore?.store_id || currentStore?.id}/users/${
          user?.id
        }`,
        {
          ...payload,
        }
      );

      if (response?.status === 200) {
        dispatch(addToast("Updated successfully"));
        fetchOrder && await fetchOrder();
        setUser && setUser((prevUserData) => ({
          ...prevUserData,
          ...response?.data?.user,
        }));
        handleClose();
      }
    } catch (err) {
      dispatch(addToast(err || "Failed to update corporation details"));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const hasChanges = useMemo(
    () => JSON.stringify(formState) !== JSON.stringify(originalState),
    [formState, originalState]
  );

  const isFormValid = () => {
    const isRequiredFieldPresent =
      formState?.name || formState?.email || formState?.phone_number;

    const contactInfo = {
      cName: formState?.cName,
      cPhoneNumber: formState?.cPhoneNumber,
      cEmail: formState?.cEmail,
    };

    const address = {
      country: formState?.country,
      line: formState?.line,
      city: formState?.city,
      state: formState?.state,
      zipcode: formState?.zipcode,
    };

    if (isObjectPartiallyFilled(contactInfo)) return false;
    if (isObjectPartiallyFilled(address)) return false;
    if (
      (formState?.email && !validateEmail(formState?.email)) ||
      (formState?.phone_number &&
        !validatePhoneNumber(formState?.phone_number))
    )
      return false;
      return isRequiredFieldPresent;
  };

  const handleOnChange = (e) => {
    setFormState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (open && user) {
      const contactPerson = user?.contacts?.[0];
      const address = getLatestAddress(user?.addresses);

      const newState = {
        name: user?.name,
        phone_number: user?.phone_number,
        email: user?.email,
        cId: contactPerson?.id,
        cName: contactPerson?.name,
        cPhoneNumber: contactPerson?.phone_number,
        cEmail: contactPerson?.email,
        addressId: address?.id,
        country: address?.country,
        line: address?.line,
        city: address?.city,
        state: address?.state,
        zipcode: address?.zipcode,
      };

      setFormState(newState);
      setOriginalState(newState);
    }
  }, [open, user]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: "20px",
          right: "8px",
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <div className="flex flex-col gap-5 w-full bg-white">
        <div className="flex flex-col gap-2 mx-4 my-6 sm:mx-8 sm:my-8">
          <p className="text-stone-800 text-2xl font-semibold font-['Montserrat'] leading-[25.20px]">
            {isCorporateCustomer ? "Edit corporation" : "Edit customer detail"}
          </p>
          <form className="flex flex-col gap-4 mt-4" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-1">
              <label className="h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                {isCorporateCustomer ? "Corporation Name" : "Name"}
              </label>
              <TextField
                name="name"
                disabled={user?.name && !isIndividualDetail}
                size="small"
                value={formState?.name}
                onChange={handleOnChange}
              />
            </div>
            {isCorporateCustomer && !isIndividualDetail ? (
              <div className="flex flex-col gap-4">
                <p className="text-[#272523] text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
                  Shipping Address
                </p>
                <AddressFields
                  size="small"
                  prefix="Street"
                  address={formState}
                  setAddress={setFormState}
                />
              </div>
            ) : (
              <div className="flex flex-col sm:flex-row sm:justify-between w-full gap-2">
                <div className="flex flex-col gap-1 sm:w-1/2">
                  <label className="h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                    Phone number *
                  </label>
                  <InputMask
                    mask="(999) 999-9999"
                    value={formState?.phone_number}
                    onChange={handleOnChange}
                  >
                    {() => (
                      <TextField
                        id="phone_number"
                        name="phone_number"
                        type="tel"
                        size="small"
                        placeholder="(123) 456-7890"
                        className="flex-1"
                        variant="outlined"
                      />
                    )}
                  </InputMask>
                </div>
                <div className="flex flex-col gap-1 sm:w-1/2">
                  <label className="h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                    Email Address
                  </label>
                  <TextField
                    name="email"
                    size="small"
                    className="w-full"
                    type="email"
                    onChange={handleOnChange}
                    value={formState?.email}
                  />
                </div>
              </div>
            )}
           {!isIndividualDetail && !isCorporateCustomer && <hr />}
            {isCorporateCustomer && !isIndividualDetail ? (
              <div className="flex flex-col gap-2">
                <p className="text-[#272523] text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
                  Contact Person
                </p>
                <div className="flex flex-col gap-1">
                  <label className="h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                    Full name
                  </label>
                  <TextField
                    name="cName"
                    size="small"
                    onChange={handleOnChange}
                    value={formState?.cName}
                  />
                </div>
                <div className="flex justify-between w-full gap-2">
                  <div className="flex flex-col gap-1 w-1/2">
                    <label className="h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                      Phone number *
                    </label>
                    <InputMask
                      mask="(999) 999-9999"
                      value={formState?.cPhoneNumber}
                      onChange={handleOnChange}
                    >
                      {() => (
                        <TextField
                          id="cPhoneNumber"
                          name="cPhoneNumber"
                          type="tel"
                          size="small"
                          placeholder="(123) 456-7890"
                          className="flex-1"
                          variant="outlined"
                        />
                      )}
                    </InputMask>
                  </div>
                  <div className="flex flex-col gap-1 w-1/2">
                    <label className="h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                      Email Address *
                    </label>
                    <TextField
                      name="cEmail"
                      size="small"
                      onChange={handleOnChange}
                      className="w-full"
                      type="email"
                      value={formState?.cEmail}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
              {!isIndividualDetail &&
                <AddressFields
                  size="small"
                  prefix="Street"
                  address={formState}
                  setAddress={setFormState}
                />
              }
              </>
            )}
            <div className="flex flex-col gap-2 md:flex-row w-full mt-5">
              <Button
                variant="outlinedSecondary"
                fullWidth
                onClick={handleClose}
              >
                Back
              </Button>
              <Button
                variant="containedPrimary"
                fullWidth
                type="submit"
                disabled={!isFormValid() || !hasChanges}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

export default UpdateCustomerDetails;
