import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { styled } from "@mui/material/styles";
import Popper from "@mui/material/Popper";
import { PlusCircle } from "../../../common/icons";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const StyledPopper = styled(Popper)({
  "& .MuiAutocomplete-listbox": {
    maxHeight: 250,
  },
});

const StyledListItem = styled("li")();

export default function Selector({
  required,
  label,
  options = [],
  multiSelect = false,
  value,
  onChange,
  size = "small",
  loading,
  isDisabled = false,
  newItemTitle = "Add New Item",
  handleNewItemClick,
}) {
  const [open, setOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState("");

  const filteredOptions = options.filter(
    (option) => option !== null && option !== undefined
  );

  const handleOptionSelect = (event, newValue) => {
    if (newValue?.id === "add-new-item") {
      handleNewItemClick(textFieldValue);
      onChange(event, { ...newValue, title: "" });
    } else {
      onChange(event, newValue);
    }

    if (!multiSelect) {
      setOpen(false);
      setIsHovered(false);
    }
  };

  const handleOptionRender = (props, option, { selected }) => {
    const isDisabled = option?.disabled || false;

    return (
      <>
        {option.isNew && <hr className="m-2" />}
        <StyledListItem
          {...props}
          key={option.isNew ? "add-new-item" : option.title}
          option={option}
          onMouseEnter={() => option.isNew && setIsHovered(true)}
          onMouseLeave={() => option.isNew && setIsHovered(false)}
          {...(option.disabled && {
            style: {
              opacity: 0.5,
              pointerEvents: "none",
              cursor: "not-allowed",
            },
          })}
        >
          {multiSelect && !(option.isNew || option.isNoOption) && (
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 6 }}
              checked={selected}
              disabled={isDisabled}
            />
          )}
          <div className="flex flex-row justify-between w-full items-center">
            <div>
              <div className="flex gap-2 items-center">
              {option.isNew && (
                <PlusCircle
                  fill={
                    textFieldValue
                      ? isHovered
                        ? "#256E22"
                        : "#4C8C4A"
                      : "gray"
                  }
                />
              )}

                <p className={`${textFieldValue && option.isNew && isHovered ? "text-[#256E22]" : ""}`}>{option?.title}</p>
              </div>

              {option.description && !option.isNew && !option.isNoOption && (
                <p className="font-questrial text-xs font-normal leading-relaxed">
                  {option.description}
                </p>
              )}
            </div>
            {option.amount && !option.isNew && !option.isNoOption && (
              <p className="text-gray-700 font-questrial text-base font-normal leading-relaxed">
                $ {option.amount}
              </p>
            )}
          </div>
        </StyledListItem>
      </>
    );
  };

  const handleFilterOptions = (options, state) => {
    const filtered = options.filter((option) =>
      option.title.toLowerCase().includes(state.inputValue.toLowerCase())
    );

    if (filtered.length === 0) {
      filtered.push({
        id: "no-options",
        title: "No options",
        isNoOption: true,
      });
    }

    if (
      !filtered.some((option) => option.isNew) &&
      newItemTitle &&
      handleNewItemClick
    ) {
      filtered.push({
        id: "add-new-item",
        title: newItemTitle,
        isNew: true,
      });
    }

    if (state?.inputValue === "") {
      setIsHovered(false);
    }

    setTextFieldValue(state?.inputValue);
    return filtered;
  };

  return (
    <Autocomplete
      multiple={multiSelect}
      options={filteredOptions}
      autoHighlight
      disabled={isDisabled || loading}
      getOptionDisabled={(option) =>
        option.isNoOption || (option.isNew && !textFieldValue)
      }
      disableCloseOnSelect={multiSelect}
      value={value || (multiSelect ? [] : null)}
      size={size}
      onChange={handleOptionSelect}
      onOpen={() => setOpen(true)}
      onClose={() => {
        setOpen(false);
        setIsHovered(false);
      }}
      open={open}
      filterOptions={handleFilterOptions}
      getOptionLabel={(option) => (option && option.title) || ""}
      PopperComponent={StyledPopper}
      renderOption={handleOptionRender}
      style={{ width: "100%" }}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      renderTags={(selected, getTagProps) =>
        multiSelect && selected.length > 0 ? (
          selected.length === 1 ? (
            <p className="mx-2">{selected[0]?.title}</p>
          ) : (
            <p className="mx-2">{selected.length} Items</p>
          )
        ) : null
      }
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          placeholder={loading && options.length === 0 ? "Loading..." : label}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#D0D5DD",
                borderRadius: "8px",
              },
            },
          }}
        />
      )}
    />
  );
}
