import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "../api";

export const fetchOrganizationData = createAsyncThunk(
  "organization/fetchOrganizationData",
  async ({ organizationId, fields = "" }, { rejectWithValue }) => {
    try {
      const associationToInclude = "addresses,active_connect_acc";
      const orgData = await getRequest(
        `organizations/${organizationId}`,
        {},
        associationToInclude?.concat(fields)
      );

      return orgData;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchCustomizeTaxes = createAsyncThunk(
  "organization/fetchCustomizeTaxes",
  async ({ organizationId }, { rejectWithValue }) => {
    try {
      const customizeTaxes = await getRequest(
        `organizations/${organizationId}/customize_taxes`,
        {}
      );
      return customizeTaxes;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchOrganizationSubscription = createAsyncThunk(
  "organization/fetchOrganizationSubscription",
  async ({ organizationId, fields="" }, { rejectWithValue }) => {
    try {
      const associationToInclude ="add_on,plan,";
      const subscription = await getRequest(
        `organizations/${organizationId}/subscriptions`,
        {},
        associationToInclude?.concat(fields)
      );

      return subscription;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
