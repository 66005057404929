import React, { useState, useEffect } from "react";
import { getRequest } from "../api";
import { useDispatch } from "react-redux";
import { addToast } from "../redux/toastSlice";
import Chip from "../components/Chip";
import { convertDateFormat } from "../utils";
import { INVOICE_HISTORY_TAB_LIST, INVOICE_STATUS } from "../constants";
import SummaryInTabs from "../components/CustomerDetails/Corporate/SummaryInTabs";

const InvoiceSummary = ({ item }) => {
  return (
    <div className="flex flex-col md:flex-row justify-between items-center border rounded">
      <div className="flex flex-col justify-between items-start gap-3 mb-3 p-5 w-full md:w-1/2">
        <div className="text-[#939291] text-xs font-normal font-['Questrial'] leading-none">
          Created on {convertDateFormat(item?.issued_at)}
        </div>
        <div className="justify-start items-center gap-2 flex">
          <span className="w-[140px] text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
            Invoice ID
          </span>
          <span className="text-[#272523] text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
            #{item?.id}
          </span>
          <Chip status={item?.status} />
        </div>
        <div className="justify-start items-center gap-2 flex">
          <span className="w-[140px] text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
            Payout cycle
          </span>
          <span className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug">
            {convertDateFormat(item?.period_start)} ~{" "}
            {convertDateFormat(item?.issued_at)}
          </span>
        </div>
        <div className="justify-start items-center gap-2 flex">
          <span className="w-[140px] text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
            Due date:
          </span>
          <span className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug">
            {convertDateFormat(item?.due_date)}
          </span>
        </div>
        <div className="justify-start items-center gap-2 flex">
          <span className="w-[140px] text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
            Invoice amount:
          </span>
          <span className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug">
            ${parseFloat(item?.amount).toFixed(2)}
          </span>
        </div>
      </div>
      <div class="hidden md:block md:border-l h-40"></div>
      <div className="flex flex-col pt-2 md:pt-0 border-t md:border-t-0 p-5 w-full md:w-1/2">
        <table className="min-w-full border-collapse ">
          <thead>
            <tr className="h-[38px] border-gray-200">
              <th className="w-[110px] p-2 text-[#939291] text-xs font-normal font-['Questrial'] leading-none text-left">
                Created date
              </th>
              <th className="w-[110px] p-2 text-[#939291] text-xs font-normal font-['Questrial'] leading-none text-left">
                Order ID(s):
              </th>
              <th className="w-[110px] p-2 text-[#939291] text-xs font-normal font-['Questrial'] leading-none text-left">
                Order total
              </th>
            </tr>
          </thead>
          <tbody>
            {item?.orders?.slice(0, 3)?.map((order, index) => (
              <tr
                key={index}
                className="h-[38px] border-b border-gray-200 hover:bg-neutral-50 transition-colors"
              >
                <td className="w-[140px] p-2 text-xs font-normal font-['Questrial'] leading-none">
                  {convertDateFormat(order?.created_at) || "-"}
                </td>
                <td className="w-[140px] p-2 text-sm font-normal font-['Questrial'] leading-none">
                  <span className="">#{order?.id}</span>{" "}
                  <Chip status={order?.status} />
                </td>
                <td className="w-[140px] p-2 text-sm font-normal font-['Questrial'] leading-none">
                  ${parseFloat(order?.net_total).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <a
          href={item?.invoice_pdf}
          className="text-center underline text-[#939291] text-xs font-normal font-['Questrial'] leading-none mt-3"
          target="_blank"
        >{`View all ${item?.orders?.length} orders (Download Invoice)`}</a>
      </div>
    </div>
  );
};

const CustomerInvoiceOverview = () => {
  const [allInvoices, setAllInvoices] = useState([]);
  const dispatch = useDispatch();

  const groupedInvoices = (invoices) => {
    return invoices.reduce(
      (acc, invoice) => {
        const { status } = invoice;

        if ([INVOICE_STATUS.PAID].includes(status)) {
          acc.paid.push(invoice);
        } else if (
          [INVOICE_STATUS.OPEN, INVOICE_STATUS.OVERDUE].includes(status)
        ) {
          acc.unpaid.push(invoice);
        }
        acc.all.push(invoice);
        return acc;
      },
      { all: [], paid: [], unpaid: [] }
    );
  };


  useEffect(() => {
    (async () => {
      try {
        const response = await getRequest(
          "customer/invoices",
          {},
          "orders"
        );
        if (response && response.length > 0) setAllInvoices(response);
      } catch (error) {
        dispatch(addToast(error))
      }
    })()
  }, [])

  return (
    <div className="m-5 flex h-[calc(100vh-120px)] overflow-auto">
      <div className="w-full">
        <SummaryInTabs
          summaryTitle="Invoice"
          summaryTabList={INVOICE_HISTORY_TAB_LIST}
          SummaryComponent={InvoiceSummary}
          groupedItems={groupedInvoices(allInvoices)}
          itemsPerTab={5}
        />
      </div>
    </div>
  );
};

export default CustomerInvoiceOverview;
