import React from "react";
import IconButton from "@mui/material/IconButton";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import NotificationIcon from "../NotificationIcon";
import { useNavigate } from "react-router-dom";
import { LeftArrowIcon } from "../../common/icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Chip } from "@mui/material";

const EntityPage = ({
  children,
  title,
  description,
  breadcrumbs = false,
  className,
  classNameLayout = "p-5",
  stickyBottomBar = false,
  childrenLayout = "p-5",
  isInfoButtonEnabled = false,
  infoButtonClass,
  infoButtonFunction,
}) => {
  const { REACT_APP_LAUNCHDARKLY_ENV, NODE_ENV } = process.env;
  const navigate = useNavigate();
  const { sampleFeature } = useFlags();

  return (
    <div
      className={`${className} ${
        stickyBottomBar
          ? "max-h-[calc(100vh-82px)] overflow-auto"
          : "h-[calc(100vh-67px)] md:h-screen"
      }`}
    >
      <div className="bg-[#F8F8F8] border-b border-stone-100">
        <div
          className={`${classNameLayout} ${"max-w-[1440px] mx-auto flex justify-between items-center"}`}
        >
          <div className="flex gap-2 items-center">
            {breadcrumbs && (
              <div
                onClick={() => navigate(-1)}
                className="flex items-center gap-2 cursor-pointer"
              >
                <LeftArrowIcon />
              </div>
            )}
            <div className="flex-col justify-start items-start gap-1 inline-flex">
              <div className="flex flex-row gap-4">
                <div className="text-zinc-900 text-2xl font-bold font-['Montserrat'] leading-[33.60px]">
                  {title}
                </div>
                {sampleFeature &&
                  !(
                    NODE_ENV === "production" &&
                    REACT_APP_LAUNCHDARKLY_ENV === "production"
                  ) && (
                    <Chip
                      label="LaunchDarkly - Sample Feature"
                      className="flex opacity-60 text-black text-sm font-normal font-['Questrial'] leading-snug text-center items-center"
                    />
                  )}
              </div>
              {description && (
                <div className="opacity-40 text-black text-base font-normal font-['Questrial'] leading-snug">
                  {description}
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-2">
            {isInfoButtonEnabled && (
              <IconButton
                className={infoButtonClass}
                onClick={infoButtonFunction}
                sx={{
                  width: 45,
                  height: 45,
                }}
              >
                <LightbulbOutlinedIcon
                  className="animate-zoom"
                  sx={{
                    color: "#939291",
                  }}
                />
              </IconButton>
            )}
            <NotificationIcon />
          </div>
        </div>
      </div>
      <div
        className={` ${childrenLayout} ${"max-w-[1440px] mx-auto flex flex-col gap-8 h-full"}`}
      >
        {children}
      </div>
    </div>
  );
};

export default EntityPage;
