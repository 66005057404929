import React, { useState } from "react";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser, setCurrentUser } from "../../redux/authSlice";
import { updateRequest } from "../../api";
import { addToast } from "../../redux/toastSlice";
import { setLoading } from "../../redux/loadingSlice";

const SetupShipStation = ({ handleBack, handleNext, activeStep }) => {
  const userData = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const [organization, setOrganization] = useState({
    shipstation_key: "",
    shipstation_secret: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrganization((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSkip = () => {
    dispatch(
      setCurrentUser({
        ...userData,
        organization: userData?.organization,
      })
    );
    handleNext();
  };

  const saveShipOrganizationData = async () => {
    try {
      dispatch(setLoading(true));
      const response = await updateRequest(
        `/organizations/${userData?.organization?.id}`,
        {
          organization: {
            shipstation_token: btoa(
              `${organization?.shipstation_key}:${organization?.shipstation_secret}`
            ),
          },
        }
      );
      dispatch(addToast("ShipStation token saved successfully!"));
      return response;
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleClickNext = async () => {
    const response = await saveShipOrganizationData();
    if (response && response.status >= 200 && response.status < 300) {
      const data = response.data;
      dispatch(
        setCurrentUser({
          ...userData,
          organization: data,
        })
      );
      handleNext();
    }
  };

  return (
    <div className="flex flex-col gap-3 m-8">
      {activeStep > 0 && (
        <p className="text-stone-400 text-base font-normal font-['Questrial'] leading-snug">
          Step {activeStep} / 4
        </p>
      )}
      <p className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
        Shipstation account setup
      </p>
      <p className="text-272523 text-base font-normal font-['Questrial'] leading-snug">
        Now you can connect your Coblr account with Shipstation to have a
        seamless shipping experience.
      </p>

      <p className="text-272523 text-base font-normal font-['Questrial'] leading-snug">
        If you don’t have a Shipstation account already, click <a className="text-[#4C8C4A] underline font-bold" href="https://www.shipstation.com/start-a-free-trial/" target="_blank" rel="noreferrer">here</a> to sign up
        one. If you already have an account, enter the API key below, so we can
        do the rest for you!
      </p>

      <p className="text-272523 text-base font-normal font-['Questrial'] leading-snug">
        Shipstation API key
      </p>

      <TextField
        name="shipstation_key"
        placeholder="Please enter"
        variant="outlined"
        value={organization?.shipstation_key}
        onChange={handleChange}
      />

      <p className="text-272523 text-base font-normal font-['Questrial'] leading-snug">
        Shipstation API Secret
      </p>

      <TextField
        name="shipstation_secret"
        placeholder="Please enter"
        variant="outlined"
        value={organization?.shipstation_secret}
        onChange={handleChange}
      />

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 mt-4 ml-auto w-[90%]">
        <div className="flex items-center justify-end">
          <div className="underline cursor-pointer" onClick={handleSkip}>
            Skip for now
          </div>
        </div>
        <Button
          variant="outlinedSecondary"
          className="w-full"
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          variant="containedPrimary"
          className="w-full"
          disabled={
            !organization?.shipstation_key.trim() ||
            !organization?.shipstation_secret.trim()
          }
          onClick={handleClickNext}
        >
          Connect
        </Button>
      </div>
    </div>
  );
};

export default SetupShipStation;
