import {
  Box,
  Card,
  CardContent,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../redux/authSlice";
import { USER_ROLES } from "../../../constants";

const OrderDetails = ({ order }) => {
  const [renderPricingContent, setRenderPricingContent] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const discount = order?.discount?.method_value === "amount" ? order?.discount?.value : (order?.sub_total * order?.discount?.value) / 100 || order?.discount_total;

  function calculateTotalSum(object) {
    if (object.price) return object.price;

    const optionsSum =
      (object.options &&
        object.options.reduce((total, option) => total + option.price, 0)) ||
      0;
    const servicesSum =
      (object.services &&
        object.services.reduce((total, service) => total + service.price, 0)) ||
      0;

    return optionsSum + servicesSum;
  };

  const groupedItems = (order?.order_line_items || []).reduce((acc, item) => {
    const { details } = item;

    let groupKey = '';
    if (item.line_item_type === 'service') {
      groupKey = `${details.options?.map(opt => opt.id).join('-')}-${details.category}-${details.services?.map(serv => `${serv.id}-${serv.type}`).join('-')}-${details.categoryType}`;
    } else if (item.line_item_type === 'product') {
      groupKey = `${details.name}-${details.type}-${details.price}-${details.category}`;
    }

    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }

    acc[groupKey].push(item);

    return acc;
  }, {});

  useEffect(() => {
    if (currentUser && order) {
      setRenderPricingContent(
        (currentUser?.id === order?.user_id && !order?.under_warranty) ||
          currentUser?.stores_as_customer?.find(
            (i) =>
              i?.store_id == order?.store_id && i?.role == USER_ROLES.CORPORATE
          )
      );
    }
  }, [currentUser, order])

  return (
    <div className="flex flex-col md:flex-row md:gap-5 gap-5 p-8 md:p-0 bg-white md:bg-transparent">
      {renderPricingContent && (
        <Box
          className={`w-full mt-5 rounded lg:border-transparent border border-[#C9C8C8]`}
        >
          <Card className="min-h-[270px] max-h-[280px]">
            <CardContent className="p-5">
              <Typography>
                <span className="text-lg font-semibold font-['Montserrat']">
                  Order Summary
                </span>
              </Typography>
              <div className="flex justify-between mt-3">
                <Typography>
                  <span className="text-[#5D5C5A]">Subtotal</span>
                </Typography>
                <Typography>
                  <span className="text-lg font-semibold font-['Montserrat']">
                    ${order?.sub_total?.toFixed(2)}
                  </span>
                </Typography>
              </div>
              <div className="flex justify-between mt-3">
                <Typography>
                  <span className="text-[#5D5C5A]">Discount</span>
                </Typography>
                <Typography>
                  <span className="text-lg font-semibold font-['Montserrat']">{`${
                    discount ? `- $${discount?.toFixed(2)}` : "$0.00"
                  }`}</span>
                </Typography>
              </div>
              {order?.shipping_total > 0 && (
                <div className="flex justify-between mt-3">
                  <Typography>
                    <span className="text-[#5D5C5A]">Shipping</span>
                  </Typography>
                  <Typography>
                    <span className="text-lg font-semibold font-['Montserrat']">{`${
                      `$${order?.shipping_total?.toFixed(2)}`
                    }`}</span>
                  </Typography>
                </div>
              )}
              <div className="flex justify-between mt-3">
                <Typography>
                  <span className="text-[#5D5C5A]">Tax</span>
                </Typography>
                <Typography>
                  <span className="text-lg font-semibold font-['Montserrat']">
                    $
                    {(
                      order?.product_sales_tax + order?.service_sales_tax
                    )?.toFixed(2) || 0.0}
                  </span>
                </Typography>
              </div>
              <hr className="border-t my-4" />
              <div className="flex justify-between font-bold">
                <Typography>
                  <span className="text-[#5D5C5A]">Total</span>
                </Typography>
                <Typography>
                  <span className="text-lg font-semibold font-['Montserrat']">
                    ${order?.net_total?.toFixed(2)}
                  </span>
                </Typography>
              </div>
            </CardContent>
          </Card>
        </Box>
      )}

      <Box
        className={`w-full mt-5 rounded border lg:border-transparent border-[#C9C8C8]`}
      >
        <Card className="min-h-[270px] max-h-[280px]">
          <CardContent className="max-h-[270px] overflow-y-auto">
            <Typography>
              <span className="text-lg font-semibold font-['Montserrat']">
                Order Details
              </span>
            </Typography>
            {Object.entries(groupedItems).map(
              ([groupKey, items], groupIndex) => {
                const uniqueItems = new Set();
                return (
                  <div key={`group-${groupKey}`} className="mt-2">
                    <Typography>
                      <span className="text-[#939291] font-normal text-base">
                        Item {groupIndex + 1}
                      </span>
                    </Typography>
                    {items.map((item, index) => {
                      const isProduct = item.line_item_type === "product";
                      const { name, category, categoryType } = item.details;
                      const itemIdentifier = isProduct
                        ? name
                        : `${category}-${categoryType}`;

                      if (uniqueItems.has(itemIdentifier)) {
                        return null;
                      }
                      uniqueItems.add(itemIdentifier);
                      return (
                        <div
                          key={item.unique_identifier || index}
                          className="mt-2"
                        >
                          <div className="flex justify-between">
                            <Typography>
                              <span className="text-lg font-semibold font-['Montserrat']">
                                {isProduct
                                  ? name
                                  : `${category} - ${categoryType}`}
                              </span>
                            </Typography>
                            <div className="text-right -mt-6 min-w-[35%]" flex>
                              {renderPricingContent && (
                                <Typography>
                                  <span className="text-lg font-semibold font-['Montserrat']">
                                    ${" "}
                                    {calculateTotalSum(item.details)?.toFixed(
                                      2
                                    )}
                                  </span>
                                </Typography>
                              )}
                              <Typography
                                variant="body2"
                                className="text-gray-400"
                              >
                                Qty: {items.length}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {groupIndex < Object.keys(groupedItems).length - 1 && (
                      <hr className="my-5" />
                    )}
                  </div>
                );
              }
            )}
          </CardContent>
        </Card>
      </Box>

      {order?.address && (
        <Box className="w-full mt-5 rounded border lg:border-transparent border-[#C9C8C8]">
          <Card className="min-h-[270px] max-h-[270px]">
            <CardContent className="p-6">
              <Typography style={{ marginTop: "12px" }}>
                <span className="text-lg font-semibold font-['Montserrat']">
                  Shipping information
                </span>
              </Typography>
              <Typography style={{ marginTop: "8px" }}>
                <span className="text-gray-400">Shipping address</span>
              </Typography>
              <Typography style={{ marginTop: "4px" }}>
                <span className="text-base font-normal font-['Questrial']">
                  {order?.address?.human_readable}
                </span>
              </Typography>
              {order?.store?.phone_number && (
                <>
                  <Typography style={{ marginTop: "8px" }}>
                    <span className="text-gray-400">Contact number</span>
                  </Typography>
                  <Typography style={{ marginTop: "4px" }}>
                    <span className="text-base font-normal font-['Questrial']">
                      {order?.store?.phone_number}
                    </span>
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        </Box>
      )}
    </div>
  );
};

export default OrderDetails;