import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import { getRequest, updateRequest } from "../../api";
import CustomTable from "../../components/Table";
import {
  CORPORATE_NEW,
  CORPORATION_DETAILS,
} from "../../constants/FrontendRoutes";
import { addToast } from "../../redux/toastSlice";
import { Plus } from "../../common/icons";
import { PAYMENT_STATUS, USER_ROLES } from "../../constants";
import { selectCurrentStore } from "../../redux/storeSlice";
import ActionMenu from "../Button/ActionMenu";
import UpdateActions from "../Button/UpdateActions";
import { convertDateFormat } from "../../utils";

const TABLE_COLUMNS = [
  {
    field: "name",
    label: "Corporation name",
    sortable: true,
    searchable: true,
    width: "100px",
    editable: true,
  },
  {
    field: "c_person",
    label: "Contact person",
    searchable: true,
    width: "120px",
    editable: true,
  },
  {
    field: "c_email",
    label: "Contact email",
    searchable: true,
    width: "150px",
    editable: true,
    regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  },
  {
    field: "c_phone_number",
    label: "Phone number",
    width: "130px",
    editable: true,
    mask: "(999) 999-9999",
    regex: /^\(\d{3}\) \d{3}-\d{4}$/,
  },
  {
    field: "next_billing",
    label: "Next billing date",
    width: "100px",
  },
  {
    field: "no_of_unpaid_orders",
    label: "# of unpaid orders",
    width: "100px",
  },
  {
    field: "unpaid_amount",
    label: "Unpaid amount",
    width: "50px",
  },
  {
    field: "actions",
    label: "",
    width: "50px",
    component: ActionMenu,
    secondaryComponent: UpdateActions,
  },
];

const transformData = (
  data,
  store,
  navigate,
  handleEdit = () => {},
  handleCancel = () => {},
  handleConfirm = () => {}
) =>
  data?.map(({ id, name, orders, contacts, billing_preferences }) => {
    const billing_pref = billing_preferences?.find(
      (item) => item?.store_id == store?.id
    );
    const billable_orders = orders.filter(
      (item) =>
        item?.store_id == store?.id &&
        item?.under_warranty &&
        item?.payment?.status == PAYMENT_STATUS.BILL_LATER
    );

    return {
      id,
      name,
      c_id: contacts?.[0]?.id,
      c_person: contacts?.[0]?.name,
      c_email: contacts?.[0]?.email,
      c_phone_number: contacts?.[0]?.phone_number,
      next_billing: convertDateFormat(billing_pref?.next_invoice),
      no_of_unpaid_orders: billable_orders?.length || 0,
      unpaid_amount: `$${billable_orders
        .reduce((sum, order) => sum + (order?.net_total || 0), 0)
        ?.toFixed(2)}`,
      link: CORPORATION_DETAILS.replace(":id", id),
      actionsProps: {
        listOfActions: [
          {
            label: "View details",
            handler: () => navigate(CORPORATION_DETAILS.replace(":id", id)),
          },
          { label: "Edit", handler: () => handleEdit(id) },
        ],
      },
      secondaryActionProps: {
        onCancel: () => handleCancel(id),
        onConfirm: (obj, row) => handleConfirm({ id, obj, row }),
      },
      editMode: false,
    };
  });

const CorporateCustomerList = () => {
  const [rows, setRows] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentStore = useSelector(selectCurrentStore);

  useEffect(() => {
    if (currentStore) fetchStoreCustomers();
  }, [currentStore]);

  const fetchStoreCustomers = async () => {
    try {
      const fields =
        "user.id,user.name,user.email,user.phone_number,order.store_id,order.net_total,order.under_warranty,billing_preference.store_id,billing_preference.next_invoice,payment.status";
      const { data } = await getRequest(
        `stores/${currentStore?.store_id}/users`,
        {
          filters: {
            role: [USER_ROLES.CORPORATE],
          },
        },
        `contacts,billing_preferences,orders.payment&fields=${fields}`
      );

      if (data?.length > 0) {
        setRows(
          transformData(
            data,
            currentStore?.store,
            navigate,
            handleEdit,
            handleCancel,
            handleConfirm
          )
        );
      } else {
        dispatch(addToast("Corporate(s) not Found", { appearance: "error" }));
      }
    } catch (error) {
      dispatch(addToast(`Error: ${error.message}`, { appearance: "error" }));
    }
  };

  const handleEdit = (id) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, editMode: true } : row))
    );
  };

  const handleCancel = (id) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, editMode: false } : row))
    );
  };

  const handleConfirm = async ({ id, obj, row }) => {
    try {
      const { name, c_person, c_email, c_phone_number } = obj;
      const response = await updateRequest(
        `stores/${currentStore?.store_id}/users/${id}`,
        {
          user: {
            name,
            contacts_attributes: [
              {
                id: row?.c_id,
                name: c_person,
                email: c_email,
                phone_number: c_phone_number,
              },
            ],
          },
        }
      );

      if (response?.status === 200) {
        setRows((prevRows) =>
          prevRows.map((row) =>
            row.id === id ? { ...row, ...obj, editMode: false } : row
          )
        );

        dispatch(addToast("Updated successfully"));
      }
    } catch (error) {
      dispatch(addToast(error || "Failed to update corporation details"));
    }
  };

  return (
    <div className="flex flex-col justify-between">
      <CustomTable
        columns={TABLE_COLUMNS}
        rows={rows}
        sortable
        searchable
        actions={
          <Button
            variant="contained"
            color="primary"
            startIcon={<Plus />}
            onClick={() => navigate(CORPORATE_NEW)}
          >
            Add new corporation
          </Button>
        }
      />
    </div>
  );
};

export default CorporateCustomerList;
