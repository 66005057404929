import React from "react";
import { Box, Button, Typography } from "@mui/material";
import theme from "../../theme";
import { Link } from "react-router-dom";
import { LetterIcon } from "../../common/icons";
import {
  CONVERSATION_DETAIL_WITH_ORDER,
  CORPORATION_DETAILS,
} from "../../constants/FrontendRoutes";

const CorporateDetail = ({ user, address, order }) => {
  const contactPerson = user?.contacts?.[0];
  return (
    <Box className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <Link
          to={CORPORATION_DETAILS.replace(":id", user?.id)}
          className="w-fit flex flex-col"
        >
          <p className="text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
            Corporation name
          </p>
          <Typography
            className="md:block hidden"
            style={theme.typography.title}
          >
            {user?.name || "N/A"}
          </Typography>
        </Link>
      </div>
      <Box className="flex flex-col gap-1">
        <Typography
          style={{
            ...theme.typography.body,
            color: theme.palette.text.secondary,
          }}
        >
          Shipping Address:
        </Typography>
        <p className="font-['Questrial'] leading-snug">{address}</p>
      </Box>
      <hr />
      <div className="flex flex-row justify-between">
        <Box className="flex flex-col gap-1">
          <Typography
            style={{
              ...theme.typography.body,
              color: theme.palette.text.secondary,
            }}
          >
            Contact Person:
          </Typography>
          <p className="font-['Questrial'] leading-snug">
            {contactPerson?.name || "N/A"}
          </p>
        </Box>
        <Box>
          <Button
            variant="contained"
            className="orderDetailStep6"
            sx={{
              bgcolor: "white",
              color: "#272523",
              fontSize: "12px",
              "&:hover": {
                bgcolor: "white",
              },
              fullWidth: true,
            }}
            startIcon={<LetterIcon />}
            component={Link}
            to={CONVERSATION_DETAIL_WITH_ORDER.replace(
              ":id",
              order?.conversation_id
            ).replace(":orderNumber", order?.id)}
          >
            Message
          </Button>
        </Box>
      </div>
      <div className="flex flex-row justify-between">
        <Box className="flex flex-col gap-1">
          <Typography
            style={{
              ...theme.typography.body,
              color: theme.palette.text.secondary,
            }}
          >
            Phone number:
          </Typography>
          <p className="font-['Questrial'] leading-snug">
            {contactPerson?.phone_number || "N/A"}
          </p>
        </Box>
        <Box className="flex flex-col gap-1">
          <Typography
            style={{
              ...theme.typography.body,
              color: theme.palette.text.secondary,
            }}
          >
            Email Address:
          </Typography>
          <p className="font-['Questrial'] leading-snug">
            {contactPerson?.email || "N/A"}
          </p>
        </Box>
      </div>
    </Box>
  );
};

export default CorporateDetail;
