import { CircularProgress } from "@mui/material";
import React from "react";

const GlobalLoading = () => {
  return (
    <div className="flex h-screen items-center justify-center text-lg">
      <CircularProgress sx={{ color: "gray" }} size={75} />
    </div>
  );
};

export default GlobalLoading;
