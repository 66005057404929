import React, { useEffect, useState, useRef } from "react";
import EntityPage from "../components/EntityPage";
import Overview from "../components/CustomerDetails/Overview";
import MessageHistory from "../components/CustomerDetails/MessageHistory";
import { USERS } from "../constants/BackendRoutes";
import { getRequest } from "../api";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToast } from "../redux/toastSlice";
import { setLoading } from "../redux/loadingSlice";
import { selectCurrentStore } from "../redux/storeSlice";
import SummaryInTabs from "../components/CustomerDetails/Corporate/SummaryInTabs";
import { ORDER_HISTORY_TAB_LIST, PAYMENT_STATUS } from "../constants";
import OrderAccordion from "../components/CustomerDetails/OrderAccordion";
import Address from "../components/CustomerAccount/Address";
import ActionMenu from "../components/Button/ActionMenu";
import CustomerNotes from "../components/CustomerDetails/Corporate/CustomerNotes";
// import ContactPerson from "../components/CustomerAccount/ContactPerson";

// const contactPersonColumns = [
//   {
//     field: "name",
//     label: "Name",
//     width: "200px",
//   },
//   {
//     field: "phone_number",
//     label: "Phone number",
//     width: "210px",
//   },
//   {
//     field: "email",
//     label: "Contact email",
//     width: "300px",
//   },
//   {
//     field: "edited_on",
//     label: "Edited on",
//     width: "150px",
//   },
//   {
//     field: "actions",
//     label: "",
//     width: "100px",
//     component: ActionMenu,
//   },
// ];

const addressColumns = [
  {
    field: "address_type",
    label: "Address type",
    width: "200px",
  },
  {
    field: "human_readable",
    label: "Address detail",
    width: "400px",
  },
  {
    field: "last_update",
    label: "Edited on",
    width: "200px",
  },
  {
    field: "actions",
    label: "",
    width: "100px",
    component: ActionMenu,
  },
];

const IndividualDetails = () => {
  const [activeLink, setActiveLink] = useState("overview");
  const [customerData, setCustomerData] = useState();
  const [individualOrders, setIndividualOrders] = useState([]);
  const [corporateOrders, setCorporateOrders] = useState([]);

  const dispatch = useDispatch();
  const currentStore = useSelector(selectCurrentStore);

  const { id } = useParams();

  const overviewRef = useRef(null);
  // const contactPersonRef = useRef(null);
  const addressRef = useRef(null);
  const orderHistoryRef = useRef(null);
  const messageHistoryRef = useRef(null);
  const notesRef = useRef(null);

  const groupedOrders = (orders) => {
    return orders?.reduce(
      (acc, order) => {
        const { status } = order;

        if (status === "quote") {
          acc.quote.push(order);
        } else if (["repaired", "delivered"].includes(status)) {
          acc.past.push(order);
        } else if (
          ["new_order", "shipping", "in_progress", "delayed"].includes(status)
        ) {
          acc.active.push(order);
        }

        acc.all.push(order);
        return acc;
      },
      { all: [], quote: [], past: [], active: [] }
    );
  };

  const linksConfig = [
    {
      id: "overview",
      label: "Overview",
      component: Overview,
      props: {
        individualOrders: individualOrders,
        corporateOrders: corporateOrders,
        currentStore: currentStore,
        setUserDetails: setCustomerData,
      },
      ref: overviewRef,
    },
    // {
    //   id: "contact_person",
    //   label: "Contact person",
    //   component: ContactPerson,
    //   props: {
    //     columns: contactPersonColumns,
    //     userDetails: customerData,
    //     setUserDetails: setCustomerData,
    //   },
    //   ref: contactPersonRef,
    // },
    {
      id: "order_history",
      label: "Order history",
      component: SummaryInTabs,
      props: {
        summaryTitle: "Order history",
        summaryTabList: ORDER_HISTORY_TAB_LIST,
        SummaryComponent: OrderAccordion,
        groupedItems: groupedOrders([...individualOrders, ...corporateOrders]),
        itemsPerTab: 5,
      },
      ref: orderHistoryRef,
    },
    {
      id: "message_history",
      label: "Message history",
      component: MessageHistory,
      props: {
        orders: [...individualOrders, ...corporateOrders],
      },
      ref: messageHistoryRef,
    },
    {
      id: "notes",
      label: "Notes",
      component: CustomerNotes,
      props: {
        userDetails: customerData,
        setUserDetails: setCustomerData,
      },
      ref: notesRef,
    },
    {
      id: "address_details",
      label: "Address details",
      component: Address,
      props: {
        columns: addressColumns,
        userDetails: customerData,
        setUserDetails: setCustomerData,
      },
      ref: addressRef,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        const user = await getRequest(
          `${USERS}/${id}`,
          {},
          "addresses,notes,conversations.store,orders.payment,orders.order_line_items,orders.last_message.user,orders_via_corporate.payment,orders_via_corporate.order_line_items,orders_via_corporate.last_message.user&fields=order.*,order_line_item.*,conversation.id,store.id,note.*"
        );

        const individual = (user?.orders || []).filter(
          (order) =>
            order?.store_id === currentStore?.store_id &&
            ![PAYMENT_STATUS.BILL_LATER, PAYMENT_STATUS.INVOICED].includes(
              order?.payment?.status
            )
        );

        const corporate = (user?.orders_via_corporate || []).filter(
          (i) => i?.store_id === currentStore?.store_id
        );

        setIndividualOrders(individual);
        setCorporateOrders(corporate);
        setCustomerData(user);
      } catch (error) {
        dispatch(addToast(error));
      } finally {
        dispatch(setLoading(false))
      }
    };

    fetchData();
  }, [currentStore, id]);

  const handleScrollToSection = (sectionRef, linkId) => {
    setActiveLink(linkId);
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <EntityPage
      title="Customer details"
      breadcrumbs
      className="bg-[#F8F8F8]"
    >
      <div className="flex gap-6">
        <div className="w-[240px] h-fit p-8 bg-white rounded-lg hidden lg:block">
          <div className="flex-col justify-start items-start gap-2 flex">
            {linksConfig.map((link) => (
              <button
                key={link.id}
                className={`text-base font-normal font-['Questrial'] leading-snug hover:bg-stone-100 w-full px-2 text-left ${
                  activeLink === link.id ? "text-stone-800" : "text-stone-400"
                }`}
                onClick={() => handleScrollToSection(link.ref, link.id)}
              >
                {link.label}
              </button>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-6 w-full h-[calc(100vh-40px-80px)] overflow-scroll">
          {linksConfig.map((link) => {
            const Component = link.component;
            return (
              <div key={link.id} ref={link.ref} id={link.id}>
                <Component
                  userData={customerData}
                  currentStore={currentStore?.store}
                  {...link.props}
                />
              </div>
            );
          })}
        </div>
      </div>
    </EntityPage>
  );
};

export default IndividualDetails;
