import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import InputMask from "react-input-mask";

export default function CollapsibleRow({ row, columns }) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [editValues, setEditValues] = useState({});

  const ActionComponent = columns.find(
    (col) => col.field === "actions"
  )?.component;

  const SecondaryActionComponent = columns.find(
    (col) => col?.field === "actions"
  )?.secondaryComponent;

  const handleChange = (column, value) => {
    setEditValues((prev) => ({ ...prev, [column.field]: value }));

    if (value && column?.regex) {
      setEditValues((prev) => ({ ...prev, [`${column.field}.error`]: column?.regex.test(value) ? "" : "Invalid format" }))
    }
  };

  useEffect(() => {
    if (row.editMode) {
      const initialValues = {};

      columns.forEach((column) => {
        if (column.editable) {
          initialValues[column?.field] = row[column?.field] || "";
        }
      });

      setEditValues(initialValues);
    }
  }, [row?.editMode, columns, row]);

  return (
    <React.Fragment>
      <TableRow
        className={`${
          !row.editMode && row.link ? "cursor-pointer" : "cursor-default"
        } ${open ? "bg-neutral-100" : ""}`}
        onClick={
          !row.editMode && row.link ? () => navigate(row.link) : undefined
        }
      >
        {columns.map((column, index) => (
          <TableCell
            key={index}
            style={{
              width: column.width,
              border: 0,
              display: column.collapsible ? "flex" : "table-cell",
              alignItems: column.collapsible ? "center" : "baseline",
              justifyContent: column.collapsible ? "space-between" : "start",
              gap: column.collapsible ? "16px" : "0",
              cursor:
                column.field === "actions" || row.editMode
                  ? "default"
                  : row.link
                  ? "pointer"
                  : "auto",
            }}
            align={column.align}
            onClick={
              column.field === "actions"
                ? (e) => e.stopPropagation()
                : undefined
            }
          >
            {column.collapsible ? (
              <>
                {row[column.field]}
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </>
            ) : column.field === "actions" ? (
              row.editMode ? (
                row.secondaryActionProps && SecondaryActionComponent ? (
                  <SecondaryActionComponent
                    {...row.secondaryActionProps}
                    editValues={editValues}
                    row={row}
                  />
                ) : null
              ) : row.actionsProps && ActionComponent ? (
                <ActionComponent {...row.actionsProps} id={row.id} />
              ) : (
                <div className="w-[2.5rem]"></div>
              )
            ) : row.editMode && column.editable ? (
              column.mask ? (
                <InputMask
                  mask={column.mask}
                  value={editValues[column.field] || ""}
                  onChange={(e) => handleChange(column, e.target.value)}
                >
                  {() => (
                    <TextField
                      size="small"
                      placeholder={column.label}
                      error={Boolean(editValues[`${column?.field}.error`])}
                      fullWidth
                      variant="outlined"
                    />
                  )}
                </InputMask>
              ) : (
                <TextField
                  size="small"
                  fullWidth
                  value={editValues[column.field] || ""}
                  error={Boolean(editValues[`${column?.field}.error`])}
                  onChange={(e) => handleChange(column, e.target.value)}
                  placeholder={column.label}
                  variant="outlined"
                />
              )
            ) : (
              row[column.field]
            )}
          </TableCell>
        ))}
      </TableRow>
      {row.collapse && (
        <TableRow>
          <TableCell style={{ padding: 0, border: 0 }} colSpan={columns.length}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table aria-label="purchases">
                <TableBody>
                  {row.collapse.map((subRow, subIndex) => (
                    <TableRow key={subIndex} className="bg-neutral-100">
                      {columns.map((subColumn, colIndex) => (
                        <TableCell
                          key={colIndex}
                          style={{ width: subColumn.width, border: 0 }}
                          align={subColumn.align}
                        >
                          {subColumn.field === "actions"
                            ? subRow.actionsProps &&
                              ActionComponent && (
                                <ActionComponent
                                  {...subRow.actionsProps}
                                  id={subRow.id}
                                />
                              )
                            : subRow[subColumn.field] || ""}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}
