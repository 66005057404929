import { jwtDecode } from "jwt-decode";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, Navigate, useLocation } from "react-router-dom";

import { LOGIN_TYPE } from "../constants";
import {
  CUSTOMER_ROUTES,
  DASHBOARD,
  LOGIN,
  ONBOARDING,
  ORDERS_OVERVIEW,
} from "../constants/FrontendRoutes";

import { fetchUserData } from "../redux/authActions";
import { selectCurrentUser, setCurrentUser } from "../redux/authSlice";
import { selectCurrentStore } from "../redux/storeSlice";
import { addToast } from "../redux/toastSlice";

import {
  retrieveTokenFromLocalStorage,
  retrieveUserIdFromLocalStorage,
} from "../utils/localStorage";

const ProtectedRoute = ({ component: Component, roles = [], flag = true, ...rest }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const jwtToken = retrieveTokenFromLocalStorage();
  const userId = retrieveUserIdFromLocalStorage();
  const isLocalStorageAvailable = Boolean(jwtToken && userId);

  const currentStoreUser = useSelector(selectCurrentStore);
  const userData = useSelector(selectCurrentUser);

  const [canAccess, setCanAccess] = useState(true);

  const decodedToken = useMemo(() => {
    if (!jwtToken) return null;
    try {
      return jwtDecode(jwtToken);
    } catch (error) {
      dispatch(addToast(error.message || "Invalid token"));
      return null;
    }
  }, [jwtToken, dispatch]);

  useEffect(() => {
    if (currentStoreUser) {
      setCanAccess(roles.includes(currentStoreUser.role) && flag);
    }
  }, [roles, currentStoreUser, flag]);

  useEffect(() => {
    if (isLocalStorageAvailable && !userData) {
      dispatch(
        fetchUserData({ id: userId, login_type: decodedToken?.login_type })
      );
    }
  }, [dispatch, isLocalStorageAvailable]);

  if (!canAccess && userData?.status !== "onboarding") {
    return <Navigate to={ORDERS_OVERVIEW} replace />;
  }

  if (!isLocalStorageAvailable) {
    dispatch(setCurrentUser(null));
    return <Navigate to={LOGIN} replace />;
  }

  const isRouteMatch = (path, availableRoutes) =>
    availableRoutes.some((route) =>
      matchPath(
        {
          path: route,
          end: false,
        },
        path
      )
    );

  if (decodedToken?.login_type === LOGIN_TYPE.CUSTOMER) {
    const availableCustomerRoutes = Object.values(CUSTOMER_ROUTES);
    if (!isRouteMatch(location.pathname, availableCustomerRoutes)) {
      return <Navigate to={CUSTOMER_ROUTES.ORDERS_OVERVIEW} replace />;
    }
    return <Component {...rest} />;
  }

  if (decodedToken?.login_type === LOGIN_TYPE.STAFF) {
    const customerRoutes = Object.values(CUSTOMER_ROUTES);
    if (customerRoutes.includes(location.pathname)) {
      return <Navigate to={DASHBOARD} replace />;
    }

    if (userData) {
      const { status } = userData;
      if (status === "onboarding" && location.pathname !== ONBOARDING) {
        return <Navigate to={ONBOARDING} replace />;
      }

      if (status !== "onboarding" && location.pathname === ONBOARDING) {
        return <Navigate to={DASHBOARD} replace />;
      }

      return <Component {...rest} />;
    }
  }

  return null;
};

export default ProtectedRoute;
