import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/KeyboardArrowLeftRounded";

import { getRoutes } from "./config";
import { Drawer, DrawerHeader } from "./muiTheme";

import Logo from "../../images/logo.png";
import CameraSVG from "../../images/camera.svg";

import { logout } from "../../redux/authActions";
import { Avatar, FormControl, MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/authSlice";
import { selectAllStores, selectCurrentStore, setStoreById } from "../../redux/storeSlice";
import { diffImageSrc } from "../../utils";
import { fetchCustomizeTaxes, fetchOrganizationData, fetchOrganizationSubscription } from "../../redux/organizationAction";
import { fetchMainCategories, fetchReadNotifications, fetchUnreadNotifications, fetchServiceTypes } from "../../redux/storeAction";
import { DASHBOARD } from "../../constants/FrontendRoutes";
import { USER_ROLES, USER_ROLES_MAPPER, USER_STATUS_MAPPER } from "../../constants";
import { initialCartState, updateCart } from "../../redux/cartSlice";
import StoreModal from "../Modal/StoreModal";
import { PlusCircle } from "../../common/icons";
import { selectIsNavbarOpen, selectOpenDropdown, setisNavbarOpen, setOpenDropdown } from "../../redux/navbarSlice";
import { useFlags } from "launchdarkly-react-client-sdk";

export default function MiniDrawer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const userData = useSelector(selectCurrentUser);
  const allStores = useSelector(selectAllStores);
  const currentStore = useSelector(selectCurrentStore);
  const flags = useFlags();

  const store = currentStore?.store;
  const role = currentStore?.role;

  const routes = getRoutes(location, flags);

  const isNavbarOpen = useSelector(selectIsNavbarOpen);
  const openDropdown = useSelector(selectOpenDropdown);
  const [isStoreModalOpen, setIsStoreModalOpen] = useState(false);

  const isMobileView = useMediaQuery({ query: "(max-width: 767px)" });

  const handleDrawerClose = () => {
    dispatch(setOpenDropdown(null));
    dispatch(setisNavbarOpen(false));
  };

  const handleDrawerToggle = () => {
    dispatch(setisNavbarOpen(!isNavbarOpen));
  };

  const handleClick = (route) => {
    if (route.name === "logout") return handleLogout();

    if (route.href) {
      window.open(route.href, "_blank");
    } else if (route?.childs?.length > 0 && isNavbarOpen) {
      dispatch(setOpenDropdown(openDropdown === route.name ? null : route.name));
    } else {
      navigate(route.path);
      if (isMobileView) handleDrawerClose();
    }
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    if (
      store?.id &&
      userData.status == USER_STATUS_MAPPER.ACTIVE
    ) {
      dispatch(
        fetchOrganizationData({
          organizationId: store?.organization_id,
        })
      );
      dispatch(
        fetchOrganizationSubscription({
          organizationId: store?.organization_id,
        })
      );
      dispatch(fetchCustomizeTaxes({ organizationId: store?.organization_id }));
      dispatch(fetchServiceTypes(store?.id));
      dispatch(fetchMainCategories(store?.id));

      const reqProps = { storeId: store?.id, userId: userData?.id };
      dispatch(updateCart(initialCartState));
      dispatch(fetchUnreadNotifications(reqProps));
      dispatch(fetchReadNotifications(reqProps));
    }
  }, [store?.id]);

  const handleStoreChange = (e) => {
    const selectedStoreId = e.target.value;
    dispatch(setStoreById(selectedStoreId));
    navigate(DASHBOARD);
  };

  return (
    <>
      {isMobileView && (
        <>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mx: "auto", margin: 1 }}
          >
            <MenuIcon sx={{ width: "35px", height: "35px" }} />
          </IconButton>

          <IconButton
            onClick={handleDrawerToggle}
            sx={{
              position: "fixed",
              top: "25px",
              left: "212px",
              zIndex: 1300,
              display: isNavbarOpen ? "block" : "none",
              backgroundColor: "white",
              borderRadius: "50%",
              padding: 0,
              border: "0.5px solid #939291",
            }}
          >
            <ChevronLeftIcon
              sx={{ display: "flex", width: "28px", height: "28px" }}
            />
          </IconButton>
        </>
      )}

      <Drawer
        variant="permanent"
        open={isNavbarOpen}
        sx={{
          display: isMobileView && !isNavbarOpen ? "none" : "block",
          "& .MuiPaper-root": {
            borderRadius: "0px 20px 20px 0px",
            boxShadow: "2px 0px 5px rgba(136, 136, 136, 0.2)",
          },
        }}
        onMouseEnter={() => dispatch(setisNavbarOpen(true))}
        onMouseLeave={() => !isMobileView && handleDrawerClose()}
      >
        <DrawerHeader>
          <ListItemButton
            sx={{
              minHeight: 40,
              justifyContent: isNavbarOpen ? "initial" : "center",
              px: 1,
              py: 0,
              ml: 4,
              mr: isNavbarOpen ? 2 : 4,
              my: 1,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isNavbarOpen ? 1 : "auto",
                justifyContent: "center",
              }}
            >
              <Avatar
                alt="Profile Image"
                src={
                  userData?.avatar_url
                    ? diffImageSrc(userData?.avatar_url)
                    : CameraSVG
                }
              />
            </ListItemIcon>
            <FormControl>
              <Select
                value={store?.id || ""}
                onChange={handleStoreChange}
                autoWidth
                displayEmpty
                disableUnderline
                variant="standard"
                sx={{
                  minWidth: 120,
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "black",
                  fontFamily: "'Questrial', sans-serif",
                  opacity: isNavbarOpen ? 1 : 0,
                  transition: "opacity 0.3s",
                  "&:focus": {
                    outline: "none",
                    boxShadow: "none",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      ml: -2,
                      mt: 0.6,
                      display: isNavbarOpen ? "block" : "none",
                    },
                  },
                  MenuListProps: {
                    sx: {
                      pb:
                        process.env?.REACT_APP_CREATE_MULTIPLE_STORES ===
                          "true" && role === USER_ROLES.ADMIN
                          ? 0
                          : 1,
                    },
                  },
                }}
                style={{ display: isNavbarOpen ? "block" : "none" }}
                renderValue={(selected) => {
                  const selectedStore = allStores.find(
                    (store) => store?.store?.id == selected
                  );
                  const storeName = selectedStore
                    ? selectedStore?.store?.name
                    : store?.name || "[Organization] name";
                  return storeName.length > 12
                    ? `${storeName.slice(0, 12)}...`
                    : storeName;
                }}
              >
                {isNavbarOpen &&
                  allStores.map((store) => (
                    <MenuItem
                      key={store?.id}
                      value={store?.store?.id}
                      style={{ display: isNavbarOpen ? "flex" : "none" }}
                      sx={{
                        minWidth: 150,
                        maxWidth: 200,
                        minHeight: 10,
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "14px",
                        fontWeight: "bold",
                        "&.Mui-selected": {
                          backgroundColor: "#F3F0E880 !important",
                        },
                      }}
                    >
                      <span className="text-wrap text-xs">
                        {store?.store?.name}
                      </span>
                      <span className="text-stone-500 text-xs pl-2">
                        {USER_ROLES_MAPPER[store?.role]}
                      </span>
                    </MenuItem>
                  ))}
                {process.env?.REACT_APP_CREATE_MULTIPLE_STORES === "true" &&
                  role === USER_ROLES.ADMIN && (
                    <>
                      <hr />
                      <MenuItem
                        onClick={() => setIsStoreModalOpen(true)}
                        style={{ display: isNavbarOpen ? "flex" : "none" }}
                        sx={{
                          minWidth: 150,
                          maxWidth: 200,
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "12px",
                          marginY: "6px",
                          fontWeight: "bold",
                          "&.Mui-selected": {
                            backgroundColor: "#F3F0E880 !important",
                          },
                        }}
                      >
                        <div className="flex justify-center items-center gap-1">
                          <PlusCircle
                            fill={"green"}
                            width="16px"
                            height="16px"
                          />
                          <span className="hover:text-[#bf975f]">
                            Create New Store
                          </span>
                        </div>
                      </MenuItem>
                    </>
                  )}
              </Select>
            </FormControl>
          </ListItemButton>
        </DrawerHeader>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <List>
            {routes.map((route, index) => {
              const showRoute = route?.roles?.includes(role);
              return route.display && (showRoute || route.isDivider) ? (
                route.isDivider ? (
                  <Divider
                    sx={{ my: 5, mx: "auto", width: "75%" }}
                    key={index}
                  />
                ) : (
                  <ListItem
                    disablePadding
                    sx={{ display: "block" }}
                    key={index}
                    className={route?.class}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 40,
                        justifyContent: isNavbarOpen ? "initial" : "center",
                        bgcolor: route.isActive ? "#F3F0E880" : "initial",
                        borderRadius: "12px",
                        px: 1,
                        py: 0,
                        ml: 4,
                        mr: isNavbarOpen ? 2 : 4,
                        my: 1,
                      }}
                      onClick={() => handleClick(route)}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: isNavbarOpen ? 1 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <route.icon
                          className="w-6 h-6"
                          isActive={route.isActive}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={route.title}
                        className={`transition-opacity duration-300 ${
                          isNavbarOpen ? "opacity-100" : "opacity-0"
                        }`}
                        primaryTypographyProps={{
                          sx: {
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            color: route.isActive ? "#272523" : "#939291",
                          },
                        }}
                      />
                      {isNavbarOpen &&
                        route?.childs?.length > 0 &&
                        (openDropdown === route.name ? (
                          <ExpandLess
                            sx={{
                              color: route.isActive ? "#272523" : "#939291",
                            }}
                          />
                        ) : (
                          <ExpandMore
                            sx={{
                              color: route.isActive ? "#272523" : "#939291",
                            }}
                          />
                        ))}
                    </ListItemButton>
                    {route?.childs?.length > 0 && (
                      <Collapse
                        in={openDropdown === route.name}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {route.childs.map((childRoute, childIndex) => (
                            <ListItemButton
                              key={childIndex}
                              className={childRoute?.class}
                              sx={{
                                borderRadius: "12px",
                                px: 1,
                                py: "3px",
                                ml: 4,
                                mr: isNavbarOpen ? 2 : 4,
                              }}
                              onClick={() => handleClick(childRoute)}
                            >
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  mr: isNavbarOpen ? 4 : "auto",
                                  justifyContent: "center",
                                }}
                              ></ListItemIcon>
                              <ListItemText
                                primary={childRoute.title}
                                primaryTypographyProps={{
                                  sx: {
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    color: childRoute.isActive
                                      ? "#272523"
                                      : "#939291",
                                  },
                                }}
                              />
                            </ListItemButton>
                          ))}
                        </List>
                      </Collapse>
                    )}
                  </ListItem>
                )
              ) : (
                <></>
              );
            })}
          </List>
          <Box sx={{ mb: 6 }}>
            <img
              src={Logo}
              alt="logo"
              className={`min-w-0 m-auto justify-center h-8 w-auto`}
            />
          </Box>
        </Box>
      </Drawer>
      <StoreModal open={isStoreModalOpen} close={setIsStoreModalOpen} />
    </>
  );
}
