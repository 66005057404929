import React, { useEffect, useState, useRef } from "react";
import EntityPage from "../components/EntityPage";
import { USERS } from "../constants/BackendRoutes";
import { getRequest } from "../api";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToast } from "../redux/toastSlice";
import { setLoading } from "../redux/loadingSlice";
import CorporateOverview from "../components/CustomerDetails/Corporate/Overview";
import SummaryInTabs from "../components/CustomerDetails/Corporate/SummaryInTabs";
import { INVOICE_HISTORY_TAB_LIST, INVOICE_STATUS, PAYMENT_STATUS } from "../constants";
import InvoiceSummary from "../components/CustomerDetails/Corporate/InvoiceSummary";
import { selectCurrentStore } from "../redux/storeSlice";
import ContactPerson from "../components/CustomerAccount/ContactPerson";
import ActionMenu from "../components/Button/ActionMenu";
import Address from "../components/CustomerAccount/Address";
import CustomerNotes from "../components/CustomerDetails/Corporate/CustomerNotes";
import OrderAccordion from "../components/CustomerDetails/OrderAccordion";

const contactPersonColumns = [
  {
    field: "name",
    label: "Name",
    width: "200px",
  },
  {
    field: "phone_number",
    label: "Phone number",
    width: "210px",
  },
  {
    field: "email",
    label: "Contact email",
    width: "300px",
  },
  {
    field: "edited_on",
    label: "Edited on",
    width: "150px",
  },
  {
    field: "actions",
    label: "",
    width: "100px",
    component: ActionMenu,
  },
];

const addressColumns = [
  {
    field: "address_type",
    label: "Address type",
    width: "200px",
  },
  {
    field: "human_readable",
    label: "Address detail",
    width: "400px",
  },
  {
    field: "last_update",
    label: "Edited on",
    width: "200px",
  },
  {
    field: "actions",
    label: "",
    width: "100px",
    component: ActionMenu,
  },
];

const CorporateDetails = () => {
  const [activeLink, setActiveLink] = useState("overview");
  const [corporationDetail, setCorporationDetail] = useState();

  const { id } = useParams();
  const dispatch = useDispatch();
  const currentStore = useSelector(selectCurrentStore);

  const overviewRef = useRef(null);
  const contactPersonRef = useRef(null);
  const addressRef = useRef(null)
  const invoiceHistoryRef = useRef(null);
  const notesRef = useRef(null)

  const groupedItems = (corporation) => {
    const statusByInvoiceId = corporation?.invoices?.reduce((acc, invoice) => {
      if (invoice?.store_id === currentStore?.store_id) {
        acc[invoice.id] = invoice.status;
      }
      return acc;
    }, {});

    const ordersByInvoiceId = corporation?.orders?.reduce((acc, order) => {
      const invoiceItem = order?.invoice_items?.[0];
      if (invoiceItem) {
        const invoiceId = invoiceItem?.invoice_id;
        if (invoiceId) {
          invoiceItem.status = statusByInvoiceId[invoiceId];
          if (!acc[invoiceId]) acc[invoiceId] = [];
          acc[invoiceId].push(order);
        }
      }
      return acc;
    }, {});

    const filteredInvoices = corporation?.invoices?.filter(
      (invoice) => invoice?.store_id === currentStore?.store_id
    );

    return filteredInvoices?.reduce(
      (acc, invoice) => {
        const { status, id } = invoice;
        invoice.orders = ordersByInvoiceId[id] || [];
        if ([INVOICE_STATUS.PAID].includes(status)) {
          acc.paid.push(invoice);
        } else if (
          [INVOICE_STATUS.OPEN, INVOICE_STATUS.OVERDUE].includes(status)
        ) {
          acc.unpaid.push(invoice);
        }
        acc.all.push(invoice);
        return acc;
      },

      {
        all: [],
        paid: [],
        unpaid: [],
        all_orders: [
          ...corporation?.orders
            ?.filter(
              (i) =>
                i?.store_id === currentStore?.store_id &&
                [PAYMENT_STATUS.BILL_LATER, PAYMENT_STATUS.INVOICED].includes(
                  i?.payment?.status
                )
            )
            ?.sort((a, b) => {
              if (!a?.invoice_items?.[0]?.invoice_id) return -1;
              if (!b?.invoice_items?.[0]?.invoice_id) return 1;
              return b?.invoice_items?.[0]?.invoice_id - a?.invoice_items?.[0]?.invoice_id;
            }),
        ],
      }
    );
  };

  const linksConfig = [
    {
      id: "overview",
      label: "Overview",
      component: CorporateOverview,
      props: {
        corporationDetail: corporationDetail,
        currentStore: currentStore?.store,
        corporateUserId: id,
        setCorporationDetail: setCorporationDetail,
      },
      ref: overviewRef,
    },
    {
      id: "contact_person",
      label: "Contact person",
      component: ContactPerson,
      props: {
        columns: contactPersonColumns,
        userDetails: corporationDetail,
        setUserDetails: setCorporationDetail,
      },
      ref: contactPersonRef,
    },
    {
      id: "address_details",
      label: "Address details",
      component: Address,
      props: {
        columns: addressColumns,
        userDetails: corporationDetail,
        setUserDetails: setCorporationDetail,
      },
      ref: addressRef,
      display: true,
    },
    {
      id: "invoice",
      label: "Invoices",
      component: SummaryInTabs,
      props: {
        summaryTitle: "Invoice",
        summaryTabList: [
          ...INVOICE_HISTORY_TAB_LIST,
          {
            label: "All orders",
            value: "all_orders",
            Component: OrderAccordion,
          },
        ],
        itemsPerTab: 5,
        SummaryComponent: InvoiceSummary,
        groupedItems: groupedItems(corporationDetail),
      },
      ref: invoiceHistoryRef,
    },
    {
      id: "notes",
      label: "Notes",
      component: CustomerNotes,
      props: {
        userDetails: corporationDetail,
        setUserDetails: setCorporationDetail,
      },
      ref: notesRef,
    },
  ];

  useEffect(() => {
    if (currentStore?.store_id && id) {
      (async () => {
        try {
          dispatch(setLoading(true));
          const response = await getRequest(
            `${USERS}/${id}`,
            {},
            `addresses,contacts,notes,invoices,orders.payment,orders.order_line_items,orders.invoice_items&fields=user.id,user.name,user.phone_number,user.email,store.id,payment.status,invoice_item.invoice_id,note.*,order_line_item.*`
          );
          if (response) setCorporationDetail(response);
        } catch (error) {
          dispatch(addToast(error));
        } finally {
          dispatch(setLoading(false));
        }
      })();
    }
  }, [currentStore, id]);

  const handleScrollToSection = (sectionRef, linkId) => {
    setActiveLink(linkId);
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <EntityPage title="Customer details" breadcrumbs className="bg-[#F8F8F8]">
      <div className="flex gap-6">
        <div className="w-[240px] h-fit p-8 bg-white rounded-lg hidden lg:block">
          <div className="flex-col justify-start items-start gap-2 flex">
            {linksConfig.map((link) => (
              <button
                key={link.id}
                className={`text-base font-normal font-['Questrial'] leading-snug hover:bg-stone-100 w-full px-2 text-left ${
                  activeLink === link.id ? "text-stone-800" : "text-stone-400"
                }`}
                onClick={() => handleScrollToSection(link.ref, link.id)}
              >
                {link.label}
              </button>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-6 w-full h-[calc(100vh-40px-80px)] overflow-scroll">
          {linksConfig.map((link) => {
            const Component = link.component;
            return (
              <div key={link.id} ref={link.ref} id={link.id}>
                <Component {...link.props} />
              </div>
            );
          })}
        </div>
      </div>
    </EntityPage>
  );
};

export default CorporateDetails;
