import React, { useState, useEffect } from "react";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import LDContextManager from "./LDContextManager";
import GlobalLoading from "../common/components/GlobalLoading";

const LDContextProvider = ({ children }) => {
  const [LDComponent, setLDComponent] = useState(null);

  useEffect(() => {
    async function initializeLD() {
      try {
        const ldProvider = await asyncWithLDProvider({
          clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID,
        });

        setLDComponent(() => ldProvider);
      } catch (error) {
        console.error("❌ Error initializing LaunchDarkly:", error);
      }
    }

    initializeLD();
  }, []);

  return LDComponent ? (
    <LDComponent>
      <LDContextManager>{children}</LDContextManager>
    </LDComponent>
  ) : (
    <GlobalLoading />
  );
};

export default LDContextProvider;
