import React from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import theme from "../../theme";
import { LetterIcon } from "../../common/icons";
import { Link } from "react-router-dom";
import {
  CONVERSATION_DETAIL_WITH_ORDER,
  INDIVIDUAL_DETAILS,
} from "../../constants/FrontendRoutes";
import InfoIcon from "@mui/icons-material/Info";
import { CustomTooltip } from "../Tooltip/CustomTooltip";

const CustomerDetail = ({
  user,
  order,
  address,
  isCorporateCustomer = false,
}) => {
  return (
    <Box className="flex flex-col gap-4">
      {user ? (
        <>
          <div className="flex flex-col gap-1">
            <div className="flex justify-between">
              <Link to={INDIVIDUAL_DETAILS.replace(":id", user?.id)}>
                <p className="text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
                  Customer #{user?.id || "N/A"}
                </p>
                <div className="flex justify-center items-center gap-2">
                  <Typography style={theme.typography.title}>
                    {user?.name || "N/A"}
                  </Typography>
                  {!user?.last_sign_in_at && (
                    <CustomTooltip
                      title={
                        <div className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug">
                          💡 The customer hasn’t signed in yet. We will show all
                          the information here once they finished signing in.
                        </div>
                      }
                      defaultColor="gray"
                    />
                  )}
                </div>
              </Link>
              {isCorporateCustomer && (
                <Box>
                  <Button
                    variant="contained"
                    className="orderDetailStep6"
                    sx={{
                      bgcolor: "white",
                      color: "#272523",
                      fontSize: "12px",
                      "&:hover": {
                        bgcolor: "white",
                      },
                      fullWidth: true,
                    }}
                    startIcon={<LetterIcon />}
                    component={Link}
                    to={CONVERSATION_DETAIL_WITH_ORDER.replace(
                      ":id",
                      order?.conversation_id
                    ).replace(":orderNumber", order?.id)}
                  >
                    Message
                  </Button>
                </Box>
              )}
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <Box className="flex flex-col gap-1">
              <Typography
                style={{
                  ...theme.typography.body,
                  color: theme.palette.text.secondary,
                }}
              >
                Phone number:
              </Typography>
              <p className="font-['Questrial'] leading-snug">
                {user?.phone_number || "N/A"}
              </p>
            </Box>
            <Box className="flex flex-col gap-1">
              <Typography
                style={{
                  ...theme.typography.body,
                  color: theme.palette.text.secondary,
                }}
              >
                Email Address:
              </Typography>
              <p className="font-['Questrial'] leading-snug">
                {user?.email || "N/A"}
              </p>
            </Box>
          </div>
          <Box className="flex flex-col gap-1">
            <Typography
              style={{
                ...theme.typography.body,
                color: theme.palette.text.secondary,
              }}
            >
              Shipping Address:
            </Typography>
            <p className="font-['Questrial'] leading-snug">{address}</p>
          </Box>
          {!isCorporateCustomer && (
            <Box>
              <Button
                variant="contained"
                className="orderDetailStep6"
                sx={{
                  bgcolor: "white",
                  color: "#272523",
                  fontSize: "16px",
                  "&:hover": {
                    bgcolor: "white",
                  },
                  fullWidth: true,
                }}
                startIcon={<LetterIcon />}
                component={Link}
                to={CONVERSATION_DETAIL_WITH_ORDER.replace(
                  ":id",
                  order?.conversation_id
                ).replace(":orderNumber", order?.id)}
              >
                Message
              </Button>
            </Box>
          )}
        </>
      ) : (
        // The customer hasn’t signed in yet. We will show all the information here once they finished signing in.
        <div className="flex flex-col gap-3">
          {!user && (
            <div className="flex gap-2 bg-[#FAFAFA] rounded-xl p-2">
              <IconButton>
                <InfoIcon />
              </IconButton>
              <p className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug">
                No customer is attached to this order. Their details will be
                shown once a customer is linked to this order.
              </p>
            </div>
          )}
        </div>
      )}
    </Box>
  );
};

export default CustomerDetail;
