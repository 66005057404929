import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from "@mui/material";
import connect_stripe from "../../../images/connect_stripe.png";
import { addToast } from "../../../redux/toastSlice";
import { postRequest, updateRequest } from "../../../api";
import { STRIPE_CONNECT_ACC_LINK_TYPES } from "../../../constants";
import { setLoading } from "../../../redux/loadingSlice";
import { useSelector } from "react-redux";
import { selectCurrentOrganization, selectSubscription } from "../../../redux/organizationSlice";
import { fetchOrganizationData } from "../../../redux/organizationAction";

const StripeConnect = () => {
  const [formData, setFormData] = useState({publishableSecret: ""});
  const [subscription, setSubscription] = useState();
  const [isDetailsSubmitted, setIsDetailSubmitted] = useState(false);
  const [isChargesEnabled, setIsChargesEnabled] = useState(false);
  const [connectPublishableKey, setConnectPublishableKey] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const currentOrganization = useSelector(selectCurrentOrganization);
  const organizationSubscription = useSelector(selectSubscription);

  const dispatch = useDispatch();

  const handleOnboardingLink = async () => {
    try {
      dispatch(setLoading(true));
      const response = await postRequest(
        `stripe/connect_accounts/create_link`,
        {
          organization_id: currentOrganization?.id,
          link_type: STRIPE_CONNECT_ACC_LINK_TYPES.ONBOARDING,
        }
      );

      if (response && response.status === 200)
        window.open(response.data?.link, "_blank");
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleKeySubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await updateRequest(
        `organizations/${currentOrganization?.id}/connected_accounts/${currentOrganization?.active_connect_acc?.id}`,
        { connect_account: { publishable_key: formData?.publishableSecret } }
      );

      if (response.status === 200) {
        setIsDialogOpen(false);
        dispatch(fetchOrganizationData({ organizationId: currentOrganization?.id }));
        dispatch(addToast("Publishable key saved."));
      }
    } catch (error) {
      dispatch(addToast(error))
    }
  };

  useEffect(() => {
    if (currentOrganization) {
      setIsDetailSubmitted(currentOrganization?.active_connect_acc?.details_submitted);
      setIsChargesEnabled(currentOrganization?.active_connect_acc?.charges_enabled);
      setConnectPublishableKey(
        currentOrganization?.active_connect_acc?.publishable_key
      );
    }
  }, [currentOrganization]);

  useEffect(() => {
    if (organizationSubscription) {
      setSubscription(organizationSubscription);
    }
  }, [organizationSubscription]);

  return (
    <div className="flex flex-col xl:flex-row gap-6 w-full justify-between rounded-lg xl:items-center">
      <div className="flex flex-col lg:flex-row gap-7 items-start lg:items-center xl:w-[70%]">
        <img
          src={connect_stripe}
          alt="StripeConnectLogo"
          className="w-16 h-auto "
        />
        <div className="flex flex-col justify-start items-start gap-1">
          <div className="text-[#272523] text-lg font-semibold font-['Montserrat'] leading-[25.20px] flex gap-2 items-center">
            Stripe Connect
            {subscription &&
              isDetailsSubmitted &&
              isChargesEnabled &&
              !connectPublishableKey && (
                <Tooltip
                  placement="top"
                  title="Click to add: Publishable key is required to accept payments by entering card details manually"
                >
                  <div
                    onClick={handleDialogOpen}
                    className="text-[#5d5c5a] text-xs font-normal font-['Questrial'] leading-snug cursor-pointer hover:underline animate-zoom"
                  >
                    (Publishable key required)
                  </div>
                </Tooltip>
              )}
          </div>
          <div className="self-stretch text-[#5d5c5a] text-base font-normal font-['Questrial'] leading-snug">
            Stripe Connect is the fastest and easiest way to integrate payments
            and financial services into platform.
          </div>
        </div>
      </div>

      {!subscription && !isDetailsSubmitted && !isChargesEnabled && (
        <div className="flex items-center gap-2 cursor-not-allowed">
          <p className="text-base font-normal text-stone-300 font-['Questrial'] leading-snug">
            Disabled
          </p>
        </div>
      )}

      {subscription && !isDetailsSubmitted && !isChargesEnabled && (
        <Button
          variant="containedPrimary"
          className={`${
            !subscription ? "cursor-not-allowed" : ""
          } rounded-lg h-fit`}
          disabled={!subscription}
          onClick={handleOnboardingLink}
        >
          Configure
        </Button>
      )}

      {subscription && isDetailsSubmitted && isChargesEnabled && (
        <div className="flex items-center gap-2">
          <div className="w-3 h-3 bg-green-500 rounded-full flex items-center justify-center"></div>
          <p className="text-base font-normal font-['Questrial'] leading-snug">
            Live
          </p>
        </div>
      )}

      {subscription && isDetailsSubmitted && !isChargesEnabled && (
        <div className="flex items-center gap-2 cursor-not-allowed">
          <p className="text-base font-normal text-stone-300 font-['Questrial'] leading-snug">
            Charge Disabled
          </p>
        </div>
      )}

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>Add Publishable Key</DialogTitle>
        <form onSubmit={handleKeySubmit}>
          <DialogContent className="self-stretch text-[#5d5c5a] text-base font-normal font-['Questrial'] leading-snug">
            Publishable key ensures that transactions are routed correctly to
            the connected account. It also helps in encrypting card details
            during transactions, maintaining PCI compliance and data security.
          </DialogContent>
          <DialogContent>
            <TextField
              label="Publishable Key"
              variant="outlined"
              name="publishableSecret"
              onChange={handleChange}
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button type="submit">Submit</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default StripeConnect;
