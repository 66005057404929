import { CheckCircleOutlineRounded } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Collapse, Divider, IconButton, Tooltip } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { markTaskDone } from "../../api/assignees";
import { PersonAdd } from "../../common/icons";
import { USER_ROLES } from "../../constants";
import { selectCurrentUser } from "../../redux/authSlice";
import { selectCurrentStore } from "../../redux/storeSlice";
import { addToast } from "../../redux/toastSlice";
import { groupServicesByType } from "../../utils";
import AssignItemModal from "../Modal/AssignItemModal";
import UsersRow from "../UsersRow";

const CollapsibleRow = ({
  index,
  isProduct,
  name,
  category,
  categoryType,
  services,
  options,
  description,
  openRowIndex,
  handleRowClick,
  assignments,
  lineItemId,
  fetchOrder,
  collapsible = true,
  showContent = true,
  showUsersRow = true,
  displayIcons = true,
  orderId,
  displayMarkDone = false,
  users,
}) => {
  const dispatch = useDispatch();
  const organizeServicesData = (value) => groupServicesByType(value);
  const lineItemData = assignments?.find(
    (asg) => asg?.order_line_item_id === lineItemId
  );
  const lineItemUsers = lineItemData?.users;

  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const currentUser = useSelector(selectCurrentUser);
  const currentStore = useSelector(selectCurrentStore);
  const storeId = currentStore?.store_id;

  const role = currentStore?.role;
  const isAdmin = role === USER_ROLES.ADMIN;
  const isManager = role === USER_ROLES.MANAGER;

  const isAdminOrManager = isAdmin || isManager;

  const displayPersonAddIcon = !collapsible && !displayMarkDone && showUsersRow;

  const handleOpenOrderAssignModal = (data) => {
    setModalData(data);
    setIsAssignModalOpen(true);
  };

  const handleCloseOrderAssignModal = () => {
    setIsAssignModalOpen(false);
  };

  const markDone = async (prop, allCompleted) => {
    try {
      const payload = isAdmin
        ? prop.map((user) => ({
            id: user.orderAssigneeId,
            is_completed: !allCompleted,
          }))
        : [
            {
              id: prop.orderAssigneeId,
              is_completed: !prop.is_completed,
            },
          ];

      const response = await markTaskDone(payload, storeId, orderId);

      if (response?.data?.success) {
        await fetchOrder();
        dispatch(addToast(response.data.message));
      }
    } catch (error) {
      dispatch(addToast("Something went wrong!"));
    }
  };

  const renderItems = (data, type, isLineItemUsers) => {
    return data.map(({ name, description, id }, index) => {
      const users = isLineItemUsers
        ? lineItemUsers
        : lineItemData?.[type]?.find((o) => o?.id === id)?.users;

      const shouldDisplayUsersRow =
        !displayMarkDone && showUsersRow && !isEmpty(users);
      const shouldShowUsersRowComponent =
        shouldDisplayUsersRow && (!isLineItemUsers || collapsible || isProduct);

      const shouldShowMarkDoneIcon = isLineItemUsers
        ? isProduct && displayMarkDone && !collapsible
        : displayMarkDone;

      const shouldShowPersonAddIcon = isLineItemUsers
        ? isProduct && displayPersonAddIcon && isEmpty(users)
        : displayPersonAddIcon && isEmpty(users);

      let myTask = users?.find((user) => user.id === currentUser?.id);

      if (myTask && isProduct) {
        myTask = {
          orderAssigneeId: myTask?.assignments?.[0]?.id,
          is_completed: myTask?.is_completed,
        };
      }

      const isHidden = !(myTask || isAdmin) || isEmpty(users);
      const allCompleted = users?.every((user) => user.is_completed);

      let finalColor = "grey";
      let tooltip = "";

      if (isAdmin && !isEmpty(users)) {
        if (allCompleted) {
          tooltip = "Mark all tasks as not done";
          finalColor = "red";
        } else {
          tooltip = "Mark all tasks as done";
          finalColor = "green";
        }
      } else {
        const isMyTaskCompleted = myTask?.is_completed;
        tooltip = isMyTaskCompleted
          ? "Mark your task as Not Done"
          : "Mark your task as Done";
        if (!isHidden) {
          finalColor = isMyTaskCompleted ? "green" : "red";
        }
      }

      return (
        <div key={index} className="flex justify-between w-full">
          {showContent && (
            <div className="flex flex-col items-start">
              <span className="font-questrial text-base mt-[10px]">{name}</span>
              {description && (
                <span className="font-questrial text-xs">
                  {description}
                </span>
              )}
            </div>
          )}

          {shouldShowUsersRowComponent && (
            <UsersRow
              fetchOrder={fetchOrder}
              users={users}
              isLineItemUsers={isLineItemUsers}
              storeId={storeId}
              displayMarkDone={displayMarkDone}
              collapsible={collapsible}
            />
          )}

          {shouldShowPersonAddIcon && (
            <PersonAdd
              style={{
                color: isAdminOrManager ? "#BFBFBF" : "#FFFF",
                marginTop: "10px",
              }}
              cursor={isAdminOrManager && "pointer"}
              onClick={() =>
                handleOpenOrderAssignModal({ itemId: id, name, type })
              }
            />
          )}

          {shouldShowMarkDoneIcon && (
            <Tooltip title={tooltip}>
              <CheckCircleOutlineRounded
                sx={{
                  marginTop: "10px",
                  color: finalColor,
                  pointerEvents: isHidden ? "none" : "auto",
                  opacity: isHidden ? 0.5 : 1,
                  transition: "color 0.3s, background-color 0.3s, opacity 0.3s",
                  cursor: isHidden ? "default" : "pointer",
                }}
                onClick={
                  !isHidden
                    ? () => markDone(isAdmin ? users : myTask, allCompleted)
                    : undefined
                }
                aria-hidden={isHidden}
                title={!isHidden ? "Mark as Done" : "Disabled"}
              />
            </Tooltip>
          )}
        </div>
      );
    });
  };

  const renderMainDetails = (displayTextInColor = false) => {
    if (!showContent && !showUsersRow) return null;

    return (
      <div
        className={`flex items-center justify-between w-full gap-5 font-questrial text-base text-[${
          displayTextInColor ? "#4C8C4A" : "gray-900"
        }]`}
      >
        {renderItems(
          [
            {
              id: lineItemId,
              name: isProduct
                ? name
                : `${category}${categoryType ? ` - ${categoryType}` : ""}`,
            },
          ],
          "products",
          true
        )}
      </div>
    );
  };

  const renderOptions = () => {
    if (!showContent && !showUsersRow) return null;

    return (
      <div>
        {showContent && (
          <p className="font-questrial text-xs text-[#939291]">Option(s)</p>
        )}
        {!isEmpty(options) && renderItems(options, "options", false)}
      </div>
    );
  };

  const renderServices = () => {
    if (!showContent && !showUsersRow) return null;

    return (
      <div>
        {Object.entries(organizeServicesData(services)).map(
          ([type, services]) => (
            <div key={type}>
              {showContent && (
                <p className="font-questrial text-xs text-[#939291]">{type} </p>
              )}
              {!isEmpty(services) && renderItems(services, "services", false)}
            </div>
          )
        )}
      </div>
    );
  };

  const renderDescription = () => {
    if (!showContent) return null;

    return (
      <div className="flex">
        <div className="flex-1 w-[200px]">
          <p className="font-questrial text-xs text-[#939291]">Description</p>
          <p className="font-questrial text-base">
            {description || "No description provided"}
          </p>
        </div>
        {/* {showUsersRow && <div className="flex-1" />} */}
      </div>
    );
  };

  const renderCollapsibleContent = () => (
    <>
      <Collapse
        in={openRowIndex === index}
        timeout={500}
        unmountOnExit
        onClick={() => handleRowClick(index)}
      >
        <div className="flex">
          <IconButton
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
            size="small"
          >
            {displayIcons && <KeyboardArrowUpIcon />}
          </IconButton>
          <div className="flex-1 flex-col gap-2 p-4">
            {showContent && (
              <p className="font-questrial text-xs text-[#5D5C5A]">
                Item {index + 1}
              </p>
            )}
            {renderMainDetails(true)}
            <div className="flex flex-col gap-2 pl-2">
              {isProduct && showContent && (
                <div className="flex items-center">
                  <div className="flex-1">
                    <p className="font-questrial text-base">{name}</p>
                  </div>
                </div>
              )}
              {!isProduct && (
                <>
                  {renderServices()}
                  <Divider />
                  {renderOptions()}
                  <Divider />
                </>
              )}
              {renderDescription()}
            </div>
          </div>
        </div>
      </Collapse>
      <Collapse
        in={openRowIndex !== index}
        timeout={500}
        unmountOnExit
        onClick={() => handleRowClick(index)}
      >
        <div className="flex">
          <IconButton
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
            size="small"
          >
            {displayIcons && <KeyboardArrowDownIcon />}
          </IconButton>
          <div className="flex-1 flex-col gap-2 p-4">
            {showContent && (
              <p className="font-questrial text-xs text-gray-700">
                Item {index + 1}
              </p>
            )}
            {renderMainDetails()}
          </div>
        </div>
      </Collapse>
    </>
  );

  const renderNonCollapsibleContent = () => (
    <div className="flex flex-col gap-2">
      {showContent && (
        <p className="font-questrial text-xs text-gray-700">Item {index + 1}</p>
      )}
      {renderMainDetails()}
      {!isProduct && renderServices()}
      {!isProduct && renderOptions()}
      {renderDescription()}
    </div>
  );

  return (
    <>
      {!collapsible && isAdminOrManager && (
        <AssignItemModal
          show={isAssignModalOpen}
          handleClose={handleCloseOrderAssignModal}
          lineItemId={lineItemId}
          orderId={orderId}
          fetchOrder={fetchOrder}
          users={users}
          {...modalData}
        />
      )}
      {collapsible ? renderCollapsibleContent() : renderNonCollapsibleContent()}
    </>
  );
};

export default CollapsibleRow;
