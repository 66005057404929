import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import CustomTable from "../Table";
import { convertDateFormat } from "../../utils";
import { deleteRequest, postRequest, updateRequest } from "../../api";
import { useDispatch } from "react-redux";
import { addToast } from "../../redux/toastSlice";
import AddressModal from "../Modal/AddressModal";
import { capitalize, isEmpty } from "lodash";
import { ADDRESSES, USERS } from "../../constants/BackendRoutes";

const Address = ({ columns, userDetails, setUserDetails }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [addressToEdit, setAddressToEdit] = useState(null);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();

  const transformAddresses = (addresses, handleEditModalOpen, handleRemove) => {
    return addresses?.map((item) => ({
      id: item.id,
      address_type: capitalize(item.address_type),
      human_readable: item.human_readable,
      last_update: convertDateFormat(item.updated_at),
      actionsProps: {
        listOfActions: [
          { label: "Edit", handler: handleEditModalOpen },
          { label: "Remove", handler: handleRemove },
        ],
      },
    }));
  };

  const handleSubmit = async (address) => {
    try {
      const isEditRequest = !!addressToEdit;
      const url = isEditRequest
        ? `${ADDRESSES}/${address?.id}`
        : `${USERS}/${userDetails?.id}${ADDRESSES}`;
      const requestMethod = isEditRequest ? updateRequest : postRequest;
      const response = await requestMethod(url, { address });

      if (response) {
        dispatch(
          setUserDetails({
            ...userDetails,
            addresses: isEditRequest
              ? userDetails.addresses.map((addr) =>
                  addr.id === address?.id ? { ...addr, ...response.data } : addr
                )
              : [...userDetails.addresses, response.data],
          })
        );

        dispatch(addToast("Address updated successfully."));
      }
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      handleModalClose();
    }
  };

  const handleModalClose = () => {
    setAddressToEdit(null);
    setModalOpen(false);
  }

  const handleEditModalOpen = (id) => {
    const address = userDetails?.addresses?.find((address) => address?.id == id);
    setAddressToEdit(address);
    setModalOpen(true);
  };

  const handleRemove = async (id) => {
    try {
      const response = await deleteRequest(`addresses/${id}`);

      if (response) {
        setUserDetails({
          ...userDetails,
          addresses: userDetails.addresses.filter((row) => row?.id != id),
        });
      }

      dispatch(addToast("Address removed successfully."));
    } catch (error) {
      dispatch(addToast(error));
    }
  };

  useEffect(() => {
    if (isEmpty(userDetails?.addresses)) {
      setRows([])
    } else {
      setRows(
        transformAddresses(
          userDetails?.addresses,
          handleEditModalOpen,
          handleRemove
        )
      );
    }
  }, [userDetails?.addresses]);

  return (
    <div className="flex flex-col gap-6 p-8 bg-white rounded-lg w-full">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between">
        <span className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
          Address information
        </span>
        <Button
          variant="textSecondary"
          onClick={() => setModalOpen(true)}
          size="small"
          startIcon={<Add />}
          sx={{
            margin: 0,
            justifyContent: "start",
          }}
        >
          Add a new address
        </Button>
      </div>
      <CustomTable columns={columns} rows={rows} />
      <AddressModal
        open={isModalOpen}
        close={handleModalClose}
        onSubmit={handleSubmit}
        address={addressToEdit}
        editable={!!addressToEdit}
      />
    </div>
  );
};

export default Address;
