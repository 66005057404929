import { Box, Button, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CrossCircle, PlusCircle } from "../common/icons";
import EntityPage from "../components/EntityPage";
import AutoCompleteInput from "../components/Form/Field/AutoComplete";
import TextInput from "../components/Form/Field/TextInput";
import FieldGroup from "../components/Form/FieldGroup";
import FieldItem from "../components/Form/FieldItem";
import FormPage from "../components/Form/FormPage";
import { getRequest, postRequest } from "../api";
import { useDispatch, useSelector } from "react-redux";
import { addToast } from "../redux/toastSlice";
import { useNavigate } from "react-router-dom";
import { selectAllCategories, selectCurrentStore } from "../redux/storeSlice";
import { validateNumberInput } from "../utils";

const INITIAL_OPTION = {
  optionName: "",
  mainCategory: [],
  categoryType: [],
  brand: "",
  costPerItem: "",
  price: "",
};

const CreateOption = () => {
  const [subCategoryOption, setSubCategoryOption] = useState({});
  const [options, setOptions] = useState([{ ...INITIAL_OPTION }]);
  const [hoverIndex, setHoverIndex] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentStore = useSelector(selectCurrentStore);
  const allCategories = useSelector(selectAllCategories);

  useEffect(() => {
    if (currentStore) handleGetSubCategories();
  }, [currentStore]);

  const handleCreateOption = async () => {
    const payload = options.map((option) => {
      const { optionName, costPerItem, price, brand, categoryType } = option;
      return {
        name: optionName,
        cost_per_item: parseFloat(costPerItem),
        price: parseFloat(price),
        brand,
        sub_categories: categoryType.map((item) => ({ id: item.value })),
      };
    });

    const res = await postRequest(
      `${process.env.REACT_APP_BACKEND_URL}/api/stores/${currentStore?.store_id}/options`,
      { options: payload }
    );

    if (res.status === 201) {
      dispatch(addToast("Option(s) added successfully."));
      navigate(-1);
    }
  };

  const handleGetSubCategories = async () => {
    const data = await getRequest(
      `${process.env.REACT_APP_BACKEND_URL}/api/stores/${currentStore?.store_id}/sub_categories`
    );

    setSubCategoryOption(
      data.reduce((acc, item) => {
        if (!acc[item.category]) acc[item.category] = [];
        acc[item.category].push({ title: item.name, value: item.id });
        return acc;
      }, {})
    );
  };

  const handleInputChange = (index, name, value) => {
    const updatedOptions = [...options];
    updatedOptions[index][name] = value;
    if (name === "mainCategory") {
      const combinedCategoryTypes = value
        .map((category) => subCategoryOption[category.value] || [])
        .flat();
      updatedOptions[index]["categoryType"] = [];
      updatedOptions[index]["categoryTypesAvailable"] = combinedCategoryTypes;
    }

    setOptions(updatedOptions);
  };

  const handleMore = () => {
    setOptions([{ ...INITIAL_OPTION }, ...options]);
  };

  const handleRemove = (index) => {
    const updatedOptions = options.filter((_, i) => i !== index);
    setOptions(updatedOptions);
  };

  const isAllFieldsFilled = options.every((option) =>
    Object.values(option).every((value) =>
      Array.isArray(value)
        ? value.length > 0
        : typeof value === "string"
        ? value.trim() !== ""
        : value
    )
  );

  return (
    <EntityPage title="Add Option" breadcrumbs stickyBottomBar>
      <FormPage>
        <Box className="flex justify-between items-center my-4">
          <Typography variant="h6">Option Details</Typography>
          <Button
            variant="textPrimary"
            startIcon={
              <PlusCircle fill={!isAllFieldsFilled ? "gray" : "green"} />
            }
            onClick={handleMore}
            disabled={!isAllFieldsFilled}
          >
            Add More
          </Button>
        </Box>

        {options.map((option, index) => (
          <React.Fragment key={index}>
            <FieldGroup>
              <FieldItem>
                <Typography width="30%" variant="body">
                  Option Name
                </Typography>
                <TextInput
                  name="optionName"
                  value={option.optionName}
                  onChange={(e) =>
                    handleInputChange(index, "optionName", e.target.value)
                  }
                />
              </FieldItem>

              <FieldItem>
                <Typography width="30%" variant="body">
                  Main Category
                </Typography>
                <AutoCompleteInput
                  name="mainCategory"
                  label="Please select"
                  options={allCategories?.map((item) => ({
                    title: item?.name,
                    value: item?.name,
                  }))}
                  value={option?.mainCategory}
                  onChange={(_, value) =>
                    handleInputChange(index, "mainCategory", value)
                  }
                  multiSelect
                />
              </FieldItem>

              <FieldItem>
                <Typography width="30%" variant="body">
                  Category Type
                </Typography>
                <AutoCompleteInput
                  name="categoryType"
                  label="Please select"
                  multiSelect
                  options={option.categoryTypesAvailable || []}
                  value={option.categoryType}
                  onChange={(_, value) =>
                    handleInputChange(index, "categoryType", value)
                  }
                />
              </FieldItem>

              <FieldItem>
                <Typography width="30%" variant="body">
                  Brand
                </Typography>
                <TextInput
                  name="brand"
                  value={option.brand}
                  onChange={(e) =>
                    handleInputChange(index, "brand", e.target.value)
                  }
                />
              </FieldItem>

              <FieldItem>
                <Typography width="30%" variant="body">
                  Cost per item
                </Typography>
                <TextInput
                  name="costPerItem"
                  type="number"
                  inputProps={{ min: 0 }}
                  onKeyDown={validateNumberInput}
                  value={option.costPerItem}
                  onChange={(e) =>
                    handleInputChange(index, "costPerItem", e.target.value)
                  }
                />
              </FieldItem>

              <FieldItem>
                <Typography width="30%" variant="body">
                  Price
                </Typography>
                <TextInput
                  name="price"
                  inputProps={{ min: 0 }}
                  type="number"
                  onKeyDown={validateNumberInput}
                  value={option.price}
                  onChange={(e) =>
                    handleInputChange(index, "price", e.target.value)
                  }
                />
              </FieldItem>
            </FieldGroup>
            <Box
              className="flex justify-end items-center my-4"
              sx={{ position: "relative" }}
            >
              {index !== options.length - 1 && (
                <Button
                  variant="textPrimary"
                  startIcon={
                    <CrossCircle
                      fill={hoverIndex === index ? "green" : "gray"}
                    />
                  }
                  onMouseEnter={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(null)}
                  onClick={() => handleRemove(index)}
                >
                  Remove
                </Button>
              )}
            </Box>
            {index < options.length - 1 && <hr className="my-10" />}
          </React.Fragment>
        ))}

        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "16px 0",
            display: "flex",
            flexDirection: "row-reverse",
            gap: "28px",
          }}
          elevation={8}
        >
          <div className="flex flex-row gap-3 mx-5">
            <Button variant="outlinedPrimary" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button
              variant="containedPrimary"
              disabled={!isAllFieldsFilled}
              onClick={handleCreateOption}
            >
              Create
            </Button>
          </div>
        </Paper>
      </FormPage>
    </EntityPage>
  );
};

export default CreateOption;
