import { Divider } from "@mui/material";
import { calculateSubTotal, calculateTotal } from "../../../../utils";

const Price = ({ cart }) => {
  const {
    serviceLineItems,
    productLineItems,
    discount,
    serviceSalesTaxRate,
    productSalesTaxRate,
  } = cart;

  if (!serviceLineItems?.length && !productLineItems?.length) return null;

  return (
    <div className="flex flex-col gap-2 pt-5 w-full">
      <div className="flex flex-row justify-between">
        <p className="text-[#5D5C5A]">Subtotal</p>
        <p className="text-lg font-semibold">
          ${parseFloat(calculateSubTotal(cart)).toFixed(2)}
        </p>
      </div>

      {!!discount && (
        <div className="flex flex-row justify-between">
          <p className="text-[#5D5C5A]">Discount</p>
          <p className="text-lg font-semibold">
            - ${cart?.totalDiscount?.toFixed(2)}
          </p>
        </div>
      )}

      {!!serviceLineItems?.length && (
        <div className="flex flex-row justify-between">
          <p className="text-[#5D5C5A]">{`Service Tax (${(
            serviceSalesTaxRate * 100
          ).toFixed(2)}%)`}</p>
          <p className="text-lg font-semibold">
            + ${cart.serviceSalesTax.toFixed(2)}
          </p>
        </div>
      )}

      {!!productLineItems?.length && (
        <div className="flex flex-row justify-between">
          <p className="text-[#5D5C5A]">{`Product Tax (${(
            productSalesTaxRate * 100
          ).toFixed(2)}%)`}</p>
          <p className="text-lg font-semibold">
            + ${cart.productSalesTax.toFixed(2)}
          </p>
        </div>
      )}

      <Divider sx={{ my: 2 }} />

      <div className="flex flex-row justify-between">
        <p className="text-[#5D5C5A]">Total</p>
        <p className="text-lg font-semibold">
          ${parseFloat(calculateTotal(cart)).toFixed(2)}
        </p>
      </div>
    </div>
  );
};

export default Price;