import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { IconButton, TextField, Button, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Selector from "../Form/Field/AutoComplete";
import {
  COUNTRIES,
  CUSTOMER_ADDRESS_TYPE_OPTIONS,
  STATES_PER_COUNTRY,
} from "../../constants";
import InputMask from "react-input-mask";

const INITIAL_STATE = {
  country: COUNTRIES[0]?.value,
  line: "",
  city: "",
  state: "",
  zipcode: "",
  address_type: "",
};
export default function AddressModal({ open, close, address, onSubmit, editable }) {
  const [formState, setFormState] = useState();

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectorChange = (field) => (_, value) => {
    setFormState((prevState) => {
      const updatedState = { ...prevState, [field]: value?.value };

      if (field === "country") {
        return {
          ...updatedState,
          line: "",
          city: "",
          state: "",
          zipcode: "",
        };
      }

      return updatedState;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formState);
    handleClose();
  };

  const handleClose = () => {
    close();
    setFormState(INITIAL_STATE);
  };

  useEffect(() => {
    if (address) {
      setFormState({
        id: address?.id,
        line: address?.line || "",
        city: address?.city || "",
        state: address?.state || "",
        zipcode: address?.zipcode || "",
        address_type: address?.address_type || "",
        country: address?.country === "USA" ? "US" : address?.country || COUNTRIES[0]?.value,
      });
    }
  }, [address]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: "20px",
          right: "8px",
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <div className="flex flex-col gap-5 w-full bg-white justify-between">
        <div className="flex flex-col gap-2 mx-8 mt-8">
          <p className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
            {editable ? "Edit Address" : "Add New Address"}
          </p>
          <p className="text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
            {editable
              ? "Update the address details below to make the necessary changes."
              : "Add a new address by filling in the required details below."}
          </p>
        </div>
        <form className="flex flex-col gap-2 mx-8 mb-8" onSubmit={handleSubmit}>
          <div className="flex flex-col gap-1">
            <label className="h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
              Country
            </label>
            <Selector
              name="country"
              label="Please select"
              size="large"
              required={true}
              options={COUNTRIES}
              value={COUNTRIES.find(
                (country) => country?.value === formState?.country
              )}
              onChange={handleSelectorChange("country")}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
              Line/Street
            </label>
            <TextField
              placeholder="Please enter"
              variant="outlined"
              name="line"
              required={true}
              value={formState?.line}
              onChange={handleOnChange}
            />
          </div>

          <div className="flex flex-col md:flex-row gap-4 md:gap-1">
            <div className="flex flex-col gap-2 w-full">
              <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                City
              </div>
              <TextField
                name="city"
                placeholder="New York"
                variant="outlined"
                value={formState?.city}
                onChange={handleOnChange}
                required
              />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                State
              </div>
              <Selector
                name="state"
                label="Please select"
                size="large"
                required={true}
                onChange={handleSelectorChange("state")}
                options={STATES_PER_COUNTRY[formState?.country] || []}
                value={
                  STATES_PER_COUNTRY[formState?.country]?.find(
                    (state) => state?.value === formState?.state
                  ) || null
                }
              />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <div className="w-40 h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
                Zip/Postal Code
              </div>
              <InputMask
                mask={formState?.country === "CA" ? "a9a 9a9" : "99999"}
                value={formState?.zipcode}
                onChange={handleOnChange}
              >
                {() => (
                  <TextField
                    type="text"
                    name="zipcode"
                    placeholder="12345"
                    className="flex-1"
                    variant="outlined"
                    required
                  />
                )}
              </InputMask>
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <label className="h-[22px] text-stone-800 text-base font-normal font-['Questrial'] leading-snug">
              Address type(s)
            </label>
            <Selector
              name="address_type"
              label="Please select"
              size="large"
              required={true}
              onChange={handleSelectorChange("address_type")}
              options={CUSTOMER_ADDRESS_TYPE_OPTIONS || []}
              value={
                CUSTOMER_ADDRESS_TYPE_OPTIONS?.find(
                  (item) => item?.value === formState?.address_type
                ) || null
              }
            />
          </div>

          <div className="flex flex-col gap-2 md:flex-row w-full mt-5">
            <Button variant="outlinedSecondary" fullWidth onClick={handleClose}>
              Back
            </Button>
            <Button variant="containedPrimary" fullWidth type="submit">
              {editable ? "Update" : "Save"}
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
}
