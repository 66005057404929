import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { QRCode } from "react-qrcode-logo";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import {
  CloudDownloadIcon,
  CrossIcon,
  LeftPointer,
  RightPointer,
} from "../../common/icons";
import { REACT_APP_URL } from "../../constants/BackendRoutes";
import { convertDateFormat } from "../../utils";
import { DETAILS } from "../../constants/FrontendRoutes";
import { addToast } from "../../redux/toastSlice";
import Small from "./PrintLayouts/Small";
import LetterSize from "./PrintLayouts/Large";

const GenerateQRCodeModal = ({
  lineItems,
  handleClose,
  isGenerateQRCode,
  user,
  dueDate,
  orderId,
  order,
}) => {
  const dispatch = useDispatch();
  const [lineItem, setLineItem] = useState([]);
  const [selectedLineItems, setSelectedLineItems] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [currentSlider, setCurrentSlider] = useState(1);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    pageStyle: `@media print {
      @page {
          size: 10in 15in;
        }
      }`,
    content: () => componentRef.current,
  });

  const handleNextStep = () => {
    setSelectedLineItems(
      lineItems.filter((item) => lineItem.includes(item.id))
    );
    setCurrentStep(currentStep + 1);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setLineItem(typeof value === "string" ? value.split(",") : value);
  };

  const handleNextStepSlider = () => {
    if (currentSlider < selectedLineItems.length) {
      setCurrentSlider(currentSlider + 1);
    }
  };

  const handlePrevStepSlider = () => {
    if (currentSlider > 1) {
      setCurrentSlider(currentSlider - 1);
    }
  };

  useEffect(() => {
    if (isGenerateQRCode) {
      if (lineItems.length === 0) {
        handleClose();
        dispatch(addToast("Products don't have QR codes "));
      }
      setCurrentStep(currentStep + 1);
      setSelectedLineItems(lineItems);
    }
  }, []);

  return (
    <div className="absolute top-1/2 left-1/2 md:w-[535px] rounded-lg w-[calc(100vw-100px)] transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg px-4 py-3 text-[16px]">
      <div className="flex flex-col gap-2">
        <div className="flex flex-row w-full justify-between items-center">
          <p className="font-bold font-['Montserrat'] text-[18px] md:text-2xl">
            Generate QR code
          </p>
          <div className="cursor-pointer flex flex-row gap-2">
            <CloudDownloadIcon
              className="md:w-auto md:h-auto h-[20px] w-[20px]"
              onClick={handlePrint}
            />
            <CrossIcon
              className="md:w-auto md:h-auto h-[28px] w-[28px]"
              onClick={handleClose}
            />
          </div>
        </div>
        {currentStep === 1 ? (
          <div className="flex flex-col gap-2">
            <p className="text-sm">
              Which item(s) would you like to generate the QR code? If you
              selected multiple items, we will generate one QR code for each
              items.
            </p>
            <div className="flex flex-row justify-between items-center">
              <p>Item(s)</p>

              <FormControl
                className="md:w-[250px] w-full"
                sx={{ m: 1, height: 50 }}
              >
                <Select
                  multiple
                  value={lineItem}
                  onChange={handleChange}
                  sx={{
                    height: 36,
                    "& .MuiSelect-select": {
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    },
                  }}
                >
                  {lineItems.length > 0 ? (
                    lineItems.map((item) => (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.details?.categoryType || item?.details?.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No options available</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            <div className="flex md:flex-row flex-col-reverse justify-end gap-2">
              <Button
                variant="outlined"
                sx={{ color: "black", borderColor: "gray" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              {lineItem?.length === 0 ? (
                <Button variant="outlined" disabled>
                  Generate
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleNextStep}
                >
                  Generate
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div className="flex md:flex-row flex-col gap-5">
            <div className="md:w-1/4 flex flex-col items-center">
              <div>
                <QRCode
                  value={`${REACT_APP_URL}/${DETAILS}/${
                    selectedLineItems[currentSlider - 1]?.unique_identifier
                  }`}
                  size={100}
                />
              </div>
              <div className="flex rounded-b-lg flex-row bg-[#B3B2B3] w-full pdf-hide">
                <div className="flex flex-row justify-around w-full items-center">
                  <LeftPointer
                    className="m-2 bg-[#B3B2B3] rounded-3xl shadow-md"
                    onClick={() => handlePrevStepSlider()}
                  />
                  <p className="text-white">{`${currentSlider}/${selectedLineItems?.length}`}</p>
                  <RightPointer
                    className="m-2 bg-[#B3B2B3] rounded-3xl shadow-md"
                    onClick={() => handleNextStepSlider()}
                  />
                </div>
              </div>
            </div>
            <div className="flex font-['Questrial'] text-[16px] flex-col gap-3 w-full">
              <div className="flex md:flex-row flex-col gap-5 w-full">
                <div className="flex flex-col gap-5 md:gap-0 md:w-1/2">
                  <div className="flex flex-col">
                    <p className="text-[#939291] text-[12px] md:text-[16px]">
                      Order ID
                    </p>
                    <p className="text-[#272523]">{orderId}</p>
                  </div>
                  {user && (
                    <div className="flex flex-col">
                      <p className="text-[#939291] text-[12px] md:text-[16px]">
                        Customer
                      </p>
                      <p className="text-[#272523]">{user?.name || "N/A"}</p>
                    </div>
                  )}
                </div>
                <div className="flex gap-5 md:gap-0 flex-col">
                  <div className="flex flex-col">
                    <p className="text-[#939291] text-[12px] md:text-[16px]">
                      Item category
                    </p>
                    <p className="text-[#272523]">
                      {selectedLineItems?.[currentSlider - 1]?.details
                        .categoryType ||
                        selectedLineItems?.[currentSlider - 1]?.details?.name}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-[#939291] text-[12px] md:text-[16px]">
                      Due Date
                    </p>
                    <p className="text-[#272523]">
                      {convertDateFormat(dueDate)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div style={{ display: "none" }}>
        <div ref={componentRef}>
          {selectedLineItems.map((_, index) => (
            <div key={index} style={{ pageBreakAfter: "always" }}>
              <LetterSize
                currentItem={index + 1}
                data={{
                  ...order,
                  orderId: orderId,
                  order_line_items: selectedLineItems,
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GenerateQRCodeModal;
