import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BasicTabs, { CustomTabPanel } from "../../../common/components/Tabs";
import { ArrowDownIcon } from "../../../common/icons";

const SummaryInTabs = ({
  summaryTitle,
  summaryTabList,
  SummaryComponent,
  groupedItems,
  itemsPerTab = 3,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const initialTab = searchParams.get("tab") || summaryTabList[0].value;
  const [currentTab, setCurrentTab] = useState(initialTab);

  const [visibleItems, setVisibleItems] = useState(
    summaryTabList.reduce((acc, tab) => {
      acc[tab.value] = itemsPerTab;
      return acc;
    }, {})
  );

  const handleTabChange = (newValue) => {
    const newTab = summaryTabList[newValue].value;
    setCurrentTab(newTab);
    searchParams.set("tab", newTab);
    navigate({ search: searchParams.toString() });
  };

  const handleViewMore = (tabValue) => {
    const totalItems = groupedItems?.[tabValue]?.length || 0;
    setVisibleItems((prevVisibleItems) => ({
      ...prevVisibleItems,
      [tabValue]: totalItems,
    }));
  };

  const renderPanelItems = (items, tabValue, TabComponent) => {
    const visibleCount = visibleItems[tabValue];
    const itemsToShow = items?.slice(0, visibleCount) || [];

    return itemsToShow.length > 0 ? (
      <div className="flex flex-col gap-[24px]">
        {itemsToShow.map((item) =>
          TabComponent ? (
            <TabComponent key={item.id} item={item} />
          ) : (
            <SummaryComponent key={item.id} item={item} />
          )
        )}
        {items?.length > visibleCount && (
          <div className="h-[38px] pl-2 py-2 rounded-lg border border-[#C9C8C8] justify-center items-center gap-2 inline-flex">
            <ArrowDownIcon />
            <span
              onClick={() => handleViewMore(tabValue)}
              className="text-[#939291] text-base font-normal font-['Questrial'] leading-snug hover:underline hover:cursor-pointer"
            >
              View all
            </span>
          </div>
        )}
      </div>
    ) : (
      <p className="px-3 py-10 text-center text-[#837d77]">
        No item(s) to display
      </p>
    );
  };

  return (
    <section className="bg-white px-5 lg:px-8 py-[24px] flex flex-col gap-[16px] rounded-[8px] w-full">
      <div className="flex flex-row items-center justify-between md:justify-start gap-[16px]">
        <div className="font-['Montserrat'] text-[24px] font-[700] text-[#272523]">
          {summaryTitle}
        </div>
      </div>
      <BasicTabs
        list={summaryTabList.map((tab) => tab.label)}
        setValue={handleTabChange}
        value={summaryTabList.findIndex((tab) => tab.value === currentTab)}
      />
      {summaryTabList.map((tab, index) => (
        <CustomTabPanel
          key={tab.value}
          value={summaryTabList.findIndex((t) => t.value === currentTab)}
          index={index}
        >
          {renderPanelItems(groupedItems?.[tab.value], tab.value, tab?.Component)}
        </CustomTabPanel>
      ))}
    </section>
  );
};

export default SummaryInTabs;
