import { IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

const UpdateActions = ({ onConfirm, onCancel, editValues, row }) => {
  const hasErrors = Object.keys(editValues).some((key) => key.endsWith(".error") && editValues[key] === "Invalid format");
  return (
    <div className="flex gap-1">
      <IconButton
        onClick={onCancel}
        sx={{
          color: "#D9534F",
          borderRadius: "3px",
          "&:hover": {
            backgroundColor: "rgba(217, 83, 79, 0.2)",
            borderRadius: "3px",
          },
          padding: "4px",
        }}
      >
        <ClearIcon sx={{ fontSize: 18 }} />
      </IconButton>

      <IconButton
        onClick={() => onConfirm(editValues, row)}
        disabled={hasErrors}
        sx={{
          color: "#5A8A6E",
          borderRadius: "3px",
          "&:hover": {
            backgroundColor: "rgba(90, 138, 110, 0.2)",
            borderRadius: "3px",
          },
          padding: "4px",
        }}
      >
        <CheckIcon sx={{ fontSize: 18 }} />
      </IconButton>
    </div>
  );
};

export default UpdateActions;
