import React, { useState, useRef, useEffect } from "react";
import MyAccount from "../components/CustomerAccount/MyAccount";
import Address from "../components/CustomerAccount/Address";
import { Button, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser, setCurrentUser } from "../redux/authSlice";
import { addToast } from "../redux/toastSlice";
import { updateRequest } from "../api";
import { hasNonEmptyValues, isObjectFieldsFilled } from "../utils";
import { setLoading } from "../redux/loadingSlice";
import { fetchUserData } from "../redux/authActions";
import ContactPerson from "../components/CustomerAccount/ContactPerson";
import BillingDetail from "../components/CustomerAccount/BillingDetail";
import BillingPreference from "../components/CustomerAccount/billingPreference";
import ActionMenu from "../components/Button/ActionMenu";

const contactPersonColumns = [
  {
    field: "name",
    label: "Name",
    width: "300px",
  },
  {
    field: "email",
    label: "Contact email",
    width: "300px",
  },
  {
    field: "phone_number",
    label: "Phone number",
    width: "300px",
  },
  {
    field: "actions",
    label: "",
    width: "100px",
    component: ActionMenu,
  },
];

const addressColumns = [
  {
    field: "human_readable",
    label: "Address",
    width: "400px",
  },
  {
    field: "address_type",
    label: "Type",
    width: "200px",
  },
  {
    field: "last_update",
    label: "Last update",
    width: "300px",
  },
  {
    field: "actions",
    label: "",
    width: "100px",
    component: ActionMenu,
  },
];

const CustomerAccount = () => {
  const [isCorporate, setIsCorporate] = useState(false);
  const [activeLink, setActiveLink] = useState("basic_information");
  const [accountDetailChanges, setAccountDetailChanges] = useState({});
  const [billingPreferenceChanges, setBillingPreferenceChanges] = useState([]);

  const accountRef = useRef(null);
  const addressRef = useRef(null);
  const contactRef = useRef(null);
  const BillingDetailRef = useRef(null);
  const BillingPreferenceRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(selectCurrentUser);

  const linksConfig = [
    {
      id: "basic_information",
      label: "Basic information",
      component: MyAccount,
      props: { setAccountDetailChanges },
      ref: accountRef,
      display: true,
    },
    {
      id: "address_information",
      label: "Address information",
      component: Address,
      props: {
        columns: addressColumns,
        userDetails: userData,
        setUserDetails: setCurrentUser,
      },
      ref: addressRef,
      display: true,
    },
    {
      id: "contact_person",
      label: "Contact person",
      component: ContactPerson,
      props: {
        columns: contactPersonColumns,
        userDetails: userData,
        setUserDetails: setCurrentUser,
      },
      ref: contactRef,
      display: true,
    },
    {
      id: "billing_detail",
      label: "Billing detail",
      component: BillingDetail,
      props: {},
      ref: BillingDetailRef,
      display: isCorporate ? true : false,
    },
    {
      id: "billing_preference",
      label: "Billing preference",
      component: BillingPreference,
      props: { setBillingPreferenceChanges },
      ref: BillingPreferenceRef,
      display: isCorporate ? true : false,
    },
  ];

  const handleScrollToSection = (sectionRef, linkId) => {
    setActiveLink(linkId);
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const isSaveDisabled = () => {
    const {
      new_password = "",
      confirm_new_password = "",
      existing_password = "",
      ...otherFields
    } = accountDetailChanges;

    const otherFieldChangesExist = hasNonEmptyValues(otherFields);
    const passwordsMatch = new_password === confirm_new_password;
    const validateBillingPreferences =
      billingPreferenceChanges.length > 0 &&
      billingPreferenceChanges.every(
        (preference) => preference.billing_cycle && preference.billing_method
      );
    const passwordFieldChangesExist = isObjectFieldsFilled({
      new_password,
      confirm_new_password,
      existing_password,
    });

    if (otherFieldChangesExist) return false;
    if (passwordFieldChangesExist && passwordsMatch) return false;
    if (validateBillingPreferences) return false;

    return true;
  };

  const updateCustomer = async () => {
    try {
      dispatch(setLoading(true));
      const response = await updateRequest(`customer/settings`, {
        user: accountDetailChanges,
        billing_preferences: billingPreferenceChanges,
      });
      if (response.status === 200) {
        dispatch(addToast("Updated successfully."));
        dispatch(fetchUserData({ id: userData?.id, login_type: 'customer' })).then(() => {
          setAccountDetailChanges({});
          setBillingPreferenceChanges([]);
        });
      }
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (userData && userData.stores_as_customer) {
      const { stores_as_customer } = userData;
      if (stores_as_customer.length > 0) {
        const isCorporate = stores_as_customer.some(
          (store) => store.role === "corporate"
        );
        setIsCorporate(isCorporate);
      }
    }
  }, [userData]);

  useEffect(() => {
    if (userData?.id)
      dispatch(fetchUserData({ id: userData?.id, login_type: "customer" }));
  }, [])

  return (
    <div className="bg-[#F8F8F8] max-h-[calc(100vh-82px)]">
      <div className="flex gap-6 w-full p-5">
        <div className="w-[320px] h-fit p-8 bg-white rounded-lg hidden md:block">
          <div className="flex-col justify-start items-start gap-2 flex">
            {linksConfig.map(
              (link) =>
                link.display && (
                  <a
                    key={link.id}
                    className={`text-base font-normal font-['Questrial'] leading-snug hover:bg-stone-100 w-full px-2 ${
                      activeLink === link.id
                        ? "text-stone-800"
                        : "text-stone-400"
                    }`}
                    href={`#${link.id}`}
                    onClick={() => handleScrollToSection(link.ref, link.id)}
                  >
                    {link.label}
                  </a>
                )
            )}
          </div>
        </div>
        <div className="flex flex-col gap-6 w-full h-[calc(100vh-82px-40px-80px)] overflow-scroll">
          {linksConfig.map((link) => {
            if (!link.display) return null;

            const Component = link.component;
            return (
              <div key={link.id} ref={link.ref}>
                <Component {...link.props} />
              </div>
            );
          })}
        </div>
      </div>

      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          padding: "16px 0",
          display: "flex",
          flexDirection: "row-reverse",
          gap: "28px",
        }}
        elevation={8}
      >
        <div className="flex flex-row gap-3 mx-5">
          <Button variant="outlinedPrimary" onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button
            variant="containedPrimary"
            disabled={isSaveDisabled()}
            onClick={updateCustomer}
          >
            Save changes
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default CustomerAccount;
