import { getDeviceType } from ".";

// Creates context object for LaunchDarkly based on the current user and store
export const getLDContext = (currentUser, currentStore) => {
  return currentUser && currentStore
    ? {
        kind: "user",
        key: currentUser?.id,
        device: getDeviceType(),
        email: currentUser?.email,
        role: currentStore?.role,
        store_id: currentStore?.store?.id || null,
        organization_id: currentStore?.store?.organization_id || null,
      }
    : {
        kind: "anonymous",
        key: "anonymous-user",
        role: "guest",
      };
};
