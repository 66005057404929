import React, { useState, useEffect } from "react";
import { Button, TextField } from "@mui/material";
import { Add, Close } from "@mui/icons-material";
import { isEmpty } from "lodash";
import MenuOptionButton from "../../Button/MenuOptionButton";
import { diffImageSrc, formatDate } from "../../../utils";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useDispatch } from "react-redux";
import { addToast } from "../../../redux/toastSlice";
import { resizeFile } from "../../../utils/imageResizer";
import ImagePlaceholder from "../../../images/image_placeholder.svg";
import { setLoading } from "../../../redux/loadingSlice";
import { deleteRequest, postRequest, updateRequest } from "../../../api";

const CustomNoteInput = ({
  note,
  index,
  setNotes,
  setUserDetails,
}) => {
  const dispatch = useDispatch();
  const menuButtonConfig = [
    {
      name: "Edit",
      function: () => {
        setNotes((prevNotes) =>
          prevNotes.map((item, idx) =>
            idx === index ? { ...item, editable: true } : item
          )
        );
      },
    },
    {
      name: "Delete",
      function: () => handleDestroy(note),
    },
  ];

  const handleDestroy = async () => {
    try {
      const response = await deleteRequest(`notes/${note?.id}`);
      if (response) {
        setNotes((prevNotes) =>
          prevNotes?.filter((item) => item?.id !== note?.id)
        );
      }
    } catch (error) {
      dispatch(addToast(error));
    }
  };

  const handleChange = (index, updatedContent) => {
    setNotes((prevNotes) => {
      const newNotes = prevNotes.map((note, i) =>
        i === index ? { ...note, content: updatedContent } : note
      );
      return newNotes;
    });
  };

  const handleFileChange = async (index, event) => {
    const files = Array.from(event.target.files);

    if (files.length > 0) {
      const resizedImages = [];

      for (const file of files) {
        if (file.type.startsWith("image/")) {
          const resizedImage = await resizeFile(file);
          resizedImages.push(resizedImage);
        } else {
          dispatch(addToast("Please select only image files."));
        }
      }

      if (!isEmpty(resizedImages)) {
        setNotes((prevNotes) =>
          prevNotes.map((item, idx) =>
            idx === index
              ? {
                  ...item,
                  attachments: [...(item.attachments || []), ...resizedImages],
                }
              : item
          )
        );
      }
    }
  };

  const handleRemoveAttachment = (attachmentIndex) => {
    setNotes((prevNotes) =>
      prevNotes.map((item, idx) =>
        idx === index
          ? {
              ...item,
              attachments: item.attachments.filter(
                (_, i) => i !== attachmentIndex
              ),
            }
          : item
      )
    );
  };

  return (
    <div className="border border-gray-300 rounded-lg p-2 w-full flex gap-6">
      <div className="flex-1">
        {note?.editable || note?.newItem ? (
          <div className="flex flex-col gap-3">
            <textarea
              value={note?.content}
              onChange={(e) => handleChange(index, e.target.value)}
              placeholder="Enter text here..."
              className="w-full m-auto outline-none resize-none placeholder-gray-500 overflow-hidden"
              rows={1}
              style={{ height: "auto" }}
              ref={(textarea) => {
                if (textarea) {
                  textarea.style.height = "auto";
                  textarea.style.height = `${textarea.scrollHeight}px`;
                }
              }}
            />

            <div className="flex flex-wrap overflow-x-auto">
              {note?.attachments?.map((image, index) => (
                <div className="flex gap-1 items-center rounded-lg border border-gray-300 m-2 relative">
                  <Close
                    style={{ fontSize: "20px" }}
                    onClick={() => handleRemoveAttachment(index)}
                    className="text-gray-400 cursor-pointer absolute -top-2 -right-2 bg-white p-[2px] shadow-lg border border-gray-300 rounded-full"
                  />
                  <img
                    key={index}
                    src={ImagePlaceholder}
                    alt="Notes"
                    className="w-[28px] h-[28px] p-1 object-cover rounded-[8px]"
                  />
                  <span className="text-[#272523] text-base p-1 font-normal font-['Questrial'] leading-snug">
                    {image?.name || image?.filename}
                  </span>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-3">
            <span className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug whitespace-pre-line">
              {note?.content}
            </span>

            <div className="flex flex-wrap gap-2 w-full overflow-x-auto">
              {note?.attachment_files?.map((image, index) => (
                <img
                  key={index}
                  src={image && diffImageSrc(image?.url)}
                  alt="Notes"
                  className="w-[131px] h-[68px] object-cover rounded-[8px]"
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="flex gap-2 flex-col justify-between items-end">
        {!(note?.editable || note?.newItem) ? (
          <>
            <MenuOptionButton data={menuButtonConfig} />
            <span className="text-[#939291] text-xs font-normal font-['Questrial'] leading-none">
              {formatDate(note?.updated_at)}
            </span>
          </>
        ) : (
          <>
            <AttachFileIcon
              className="cursor-pointer hover:text-[#4c8c4a] mt-auto"
              onClick={() =>
                document.getElementById(`fileInput-${index}`).click()
              }
            />
            <input
              type="file"
              id={`fileInput-${index}`}
              className="hidden"
              accept="image/*"
              multiple
              onChange={(e) => handleFileChange(index, e)}
            />
          </>
        )}
      </div>
    </div>
  );
};

const CustomerNotes = ({ userDetails, setUserDetails }) => {
  const [notes, setNotes] = useState([]);
  const dispatch = useDispatch();

  const handleSubmit = async (note) => {
    dispatch(setLoading(true));

    try {
      const url = note?.editable
        ? `notes/${note?.id}`
        : `users/${userDetails?.id}/notes`;

      const requestMethod = note?.editable ? updateRequest : postRequest;
      const response = await requestMethod(
        url,
        {
          note: {
            content: note?.content || "",
            attachments: note?.attachments || [],
          },
        },
        { "Content-Type": "multipart/form-data" }
      );

     if (response) {
       const updatedNote = {
         ...response?.data,
         attachments: [...(response?.data?.attachment_files || [])],
         editable: false,
         newItem: false,
       };

       setNotes((prevNotes) =>
         note?.editable
           ? prevNotes?.map((item) =>
               item?.id === note?.id ? updatedNote : item
             )
           : [updatedNote, ...prevNotes?.filter((item) => !item?.newItem)]
       );
     }
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleNewItem = () => {
    setNotes([{ content: "", editable: false, newItem: true }, ...notes]);
  };

  useEffect(() => {
    setNotes(
      (userDetails?.notes || []).map((note) => ({
        ...note,
        attachments: [...note?.attachment_files],
        editable: false,
        newItem: false,
      }))
    );
  }, [userDetails?.notes]);

  return (
    <div className="flex flex-col gap-6 p-8 bg-white rounded-lg w-full">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between">
        <span className="text-stone-800 text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
          Notes
        </span>
        <Button
          variant="textSecondary"
          disabled={notes?.some((note) => note?.newItem)}
          onClick={handleNewItem}
          size="small"
          startIcon={<Add />}
          sx={{
            margin: 0,
            justifyContent: "start",
          }}
        >
          Add a new note
        </Button>
      </div>

      {isEmpty(notes) ? (
        <div className="flex items-center h-[38px] pl-2 py-6 rounded-lg border border-[#C9C8C8] w-full">
          <span className="text-[#837d77] text-base font-normal font-['Questrial'] leading-snug text-center w-full">
            No item(s) to display
          </span>
        </div>
      ) : (
        <div className="flex flex-col">
          {notes?.map((note, index) => (
            <div key={index}>
              <div className="flex gap-2 items-end">
                <CustomNoteInput
                  note={note}
                  index={index}
                  setNotes={setNotes}
                  setUserDetails={setUserDetails}
                />
                {(note?.editable || note?.newItem) && (
                  <Button
                    variant="contained"
                    className="h-fit"
                    onClick={() => handleSubmit(note)}
                  >
                    Save
                  </Button>
                )}
              </div>
              {index < notes?.length - 1 && (
                <hr className="my-5 border-gray-300" />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomerNotes;
