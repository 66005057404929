import { getRequest, postRequest } from "./requests";
import { calculateTotal, transformCreateOrderPayload } from "../utils";
import { POS_NEW_ORDER_STEPS, USER_ROLES_MAPPER } from "../constants";
import { addToast } from "../redux/toastSlice";
import { setLoading } from "../redux/loadingSlice";
import { updateCart } from "../redux/cartSlice";
import { updateCurrentStep } from "../redux/currentStepSlice";

export const getPOS = async (
  setProducts,
  setSubCategories,
  currentStore,
  dispatch
) => {
  try {
    dispatch(setLoading(true));
    const { products, sub_categories: subCategories } = await getRequest(
      `stores/${currentStore?.store_id}`,
      {},
      "products,sub_categories.options,sub_categories.services"
    );

    setProducts(products);
    setSubCategories(subCategories);
  } catch (error) {
    dispatch(addToast(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getStateTaxes = async (state, dispatch) => {
  try {
    const stateTaxes = await getRequest("taxes", {
      filters: { state_abbreviation: state },
    });

    return stateTaxes.find((item) => item.state_abbreviation === state);
  } catch (error) {
    dispatch(addToast(error.message));
  }
};

export const createPayment = async (cart, currentStore, currentOrganization, dispatch) => {
  try {
    const paymentResponse = await postRequest(
      `stores/${currentStore?.store_id}/payments`,
      {
        payment: {
          currency: currentOrganization?.active_connect_acc?.currency || 'usd',
          amount: calculateTotal(cart),
        },
      }
    );
    if (paymentResponse && paymentResponse.status == 201) {
      return paymentResponse?.data;
    }
  } catch (error) {
    dispatch(addToast(error));
  }
};

export const posCreateOrder = async (orderType, cart, currentStore, dispatch, billLater = false) => {
  const orderPayload = transformCreateOrderPayload(orderType, cart, billLater);

  try {
    const orderResponse = await postRequest(
      `stores/${currentStore?.store_id}/orders`,
      {
        order: orderPayload,
        payment_id: cart?.payment_id,
        is_customer_skipped: cart?.isCustomerSkipped,
        customer: {
          filters: {
            id: cart?.attached_customer?.id,
          },
        },
      }
    );

    if (orderResponse && orderResponse.status == 201) {
      dispatch(updateCart({...cart, orderId: orderResponse?.data?.id, isBillLater: billLater, data: orderResponse?.data }))
      dispatch(updateCurrentStep(POS_NEW_ORDER_STEPS.ORDER_SUCCESS))
    }
  } catch (error) {
    dispatch(addToast(error || "Error."));
  }
};

export const getCustomizeTaxes = async (organizationId, dispatch) => {
  try {
    const customizeTaxes = await getRequest(
      `organizations/${organizationId}/customize_taxes`,
      {}
    );
    return customizeTaxes;
  } catch (error) {
    dispatch(addToast(error));
  }
};
