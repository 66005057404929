import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Chip from "../../Chip";
import { ArrowDownIcon } from "../../../common/icons";
import { capitalize, convertDateFormat } from "../../../utils";
import { ORDERS_DETAILS } from "../../../constants/FrontendRoutes";
import { BILLING_METHOD, INVOICE_STATUS } from "../../../constants";
import { Button } from "@mui/material";
import PaymentIcon from '@mui/icons-material/Payment';
import { useDispatch } from "react-redux";
import { addToast } from "../../../redux/toastSlice";
import { postRequest } from "../../../api";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../redux/authSlice";
import { setLoading } from "../../../redux/loadingSlice";
import { selectCurrentStore } from "../../../redux/storeSlice";

const InvoiceSummary = ({ item }) => {
  const [expand, setExpand] = useState(false);

  const userData = useSelector(selectCurrentUser)
  const currentStore = useSelector(selectCurrentStore);
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const handleConfirmCharge = async () => {
    try {
      dispatch(setLoading(true));
      const { status, data } = await postRequest(
        `stores/${currentStore?.store_id}/users/${userData?.id}/invoices/${item?.id}/confirm_auto_charge`
      );

      if (status == 200 && data?.payment_intent?.status == "succeeded") {
        dispatch(addToast('Invoice successfully Auto-Charged.'))
      }
    } catch (error) {
      dispatch(addToast(error));
    } finally {
      dispatch(setLoading(false));
    }
  }

  const InvoiceOrders = ({ orders }) => {
    const handleOrderClick = (orderId) => {
      if (orderId) {
        navigate(ORDERS_DETAILS.replace(":id", orderId));
      }
    }

    return (
      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse">
          <thead>
            <tr className="h-[38px] border-t border-gray-200">
              <th className="w-[110px] p-2 text-[#939291] text-xs font-normal font-['Questrial'] leading-none text-left">
                Created date
              </th>
              <th className="w-[110px] p-2 text-[#939291] text-xs font-normal font-['Questrial'] leading-none text-left">
                Order ID(s):
              </th>
              <th className="w-[110px] p-2 text-[#939291] text-xs font-normal font-['Questrial'] leading-none text-left">
                Status
              </th>
              <th className="w-[110px] p-2 text-[#939291] text-xs font-normal font-['Questrial'] leading-none text-left">
                Order total
              </th>
            </tr>
          </thead>
          <tbody>
            {orders?.map((order, index) => (
              <tr
                key={index}
                className="h-[38px] border-t border-gray-200 hover:bg-neutral-50 transition-colors"
              >
                <td className="w-[140px] p-2 text-[#272523] text-xs font-normal font-['Questrial'] leading-none">
                  {convertDateFormat(order?.created_at) || "-"}
                </td>
                <td
                  className={`w-[140px] p-2 text-base font-normal font-['Questrial'] leading-none text-[#272523] hover:text-[#256e22] hover:underline hover:cursor-pointer`}
                  onClick={() => handleOrderClick(order?.id)}
                >
                  #{order?.id || "-"}
                </td>
                <td className="w-[140px] p-2 text-[#272523] text-base font-normal font-['Questrial'] leading-none">
                  {capitalize(order?.status)}
                </td>
                <td className="w-[140px] p-2 text-[#272523] text-base font-normal font-['Questrial'] leading-none">
                  ${parseFloat(order?.net_total).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="flex flex-col md:flex-row border border-[#C9C8C8] rounded-[8px]">
      <div
        onClick={() => setExpand(!expand)}
        className="h-full self-center mx-[8px] cursor-pointer my-2"
      >
        <ArrowDownIcon transform={expand ? "rotate(180)" : ""} />
      </div>
      <div className="flex flex-col grow gap-4 p-5 font-['Questrial'] border-t-[0.5px] border-t-[#c9c8c8] md:border-l-[0.5px] md:border-l-[#c9c8c8] md:border-r-[0.5px] md:border-r-[#c9c8c8]">
        <div className="flex items-center justify-between">
          <span className="text-[#939291] text-xs font-normal font-['Questrial'] leading-none">
            Created on {convertDateFormat(item?.issued_at)}
          </span>
          {item?.collection_method == BILLING_METHOD.PAY_WITH_CARD_ON_FILE &&
            item?.status == INVOICE_STATUS.OPEN && (
              <Button
                startIcon={<PaymentIcon/>}
                variant="textPrimary"
                className="animate-zoom"
                onClick={handleConfirmCharge}
              >
                Confirm & Charge
              </Button>
            )}
        </div>
        <div className="justify-start items-center gap-2 flex">
          <span className="w-[140px] text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
            Invoice ID
          </span>
          <span className="text-[#272523] text-lg font-semibold font-['Montserrat'] leading-[25.20px]">
            #{item?.id}
          </span>
          <Chip status={item?.status} />
        </div>
        <div className="justify-start items-center gap-2 flex">
          <span className="w-[140px] text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
            Payout cycle
          </span>
          <span className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug">
            {convertDateFormat(item?.period_start)} ~{" "}
            {convertDateFormat(item?.issued_at)}
          </span>
        </div>
        <div className="justify-start items-center gap-2 flex">
          <span className="w-[140px] text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
            Due date:
          </span>
          <span className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug">
            {convertDateFormat(item?.due_date)}
          </span>
        </div>
        <div className="justify-start items-center gap-2 flex">
          <span className="w-[140px] text-[#939291] text-base font-normal font-['Questrial'] leading-snug">
            Invoice amount:
          </span>
          <span className="text-[#272523] text-base font-normal font-['Questrial'] leading-snug">
            ${parseFloat(item?.amount).toFixed(2)}
          </span>
        </div>

        {expand && <InvoiceOrders orders={item?.orders} />}
      </div>
    </div>
  );
};

export default InvoiceSummary;
