import React, { useState, useEffect, useRef } from "react";
import {
  IconButton,
  InputAdornment,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { validateNumberInput } from "../../../../utils";

const EditableProductPrice = ({ value, onSave, onCancel }) => {
  const [price, setPrice] = useState(value.toFixed(2));
  const inputRef = useRef(null);

  useEffect(() => {
    const length = typeof price === "string" ? price.length : price.toFixed(2).length;
    if (inputRef.current) {
      inputRef.current.style.width = `${length - 0.9}ch`;
    }
  }, [price]);

  const handleSave = () => {
    onSave(price);
    onCancel();
  };

  const handleCancel = () => {
    setPrice(parseFloat(value));
    onCancel();
  };

  return (
    <div className="flex items-center border rounded-lg px-1 py-1">
      <InputAdornment position="start" className="text-gray-500">
        $
      </InputAdornment>
      <input
        ref={inputRef}
        type="number"
        inputProps={{ min: 0 }}
        className="text-black text-right focus:outline-none no-arrows"
        value={price}
        onKeyDown={validateNumberInput}
        onChange={(e) => {
          const newValue = Math.max(0, parseFloat(e.target.value));
          setPrice(isNaN(newValue) ? 0 : newValue);
        }}
        style={{
          MozAppearance: "textfield",
        }}
      />
      <div className="flex items-center ml-2">
        <IconButton onClick={handleCancel} sx={{ m: 0, p: 0 }}>
          <CloseIcon fontSize="small" className="text-red-500" />
        </IconButton>
        <IconButton onClick={handleSave} sx={{ m: 0, p: 0 }}>
          <CheckIcon size="small" className="text-green-500" />
        </IconButton>
      </div>
    </div>
  );
};

export default EditableProductPrice;