import { createSlice } from "@reduxjs/toolkit";
import { logout } from "./authActions";

const currentsStepSlice = createSlice({
  name: "currentStep",
  initialState: 0,
  reducers: {
    updateCurrentStep: (state, action) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => 0);
  },
});

export const { updateCurrentStep } = currentsStepSlice.actions;
export const selectCurrentStep = (state) => state?.currentStep;
export default currentsStepSlice.reducer;
