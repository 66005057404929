import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import theme from "../../theme";
import { EditIcon } from "../../common/icons";
import { updateRequest } from "../../api";
import { addToast } from "../../redux/toastSlice";
import { selectCurrentStore } from "../../redux/storeSlice";
import { useMediaQuery } from "react-responsive";
import { getLatestAddress } from "../../utils";
import CustomerDetail from "./CustomerDetail";
import BasicTabs, { CustomTabPanel } from "../../common/components/Tabs";
import { ORDER_CUSTOMER_DETAIL_TAB_LISTS } from "../../constants";
import CorporateDetail from "./CorporateDetail";
import UpdateCustomerDetails from "../Modal/UpdateCustomerDetails";
import { setLoading } from "../../redux/loadingSlice";

const OrderDetailsFooter = ({ user, order, setShowGreenTick, fetchOrder }) => {
  const dispatch = useDispatch();
  const currentStore = useSelector(selectCurrentStore);
  const isMdUp = useMediaQuery({ query: "(max-width: 767px)" });

  const [notes, setNotes] = useState({
    customerNotes: order.customer_notes || "",
    internalNotes: order.internal_notes || "",
  });
  const [currentTab, setCurrentTab] = useState(ORDER_CUSTOMER_DETAIL_TAB_LISTS?.[0]?.value);
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(user);
  const isCorporateCustomer =  order?.under_warranty ? true : false;

  const resetFields = () => {
    setNotes({
      customerNotes: order.customer_notes,
      internalNotes: order.internal_notes,
    });
  };

  const isButtonDisabled = () =>
    notes.customerNotes === order.customer_notes &&
    notes.internalNotes === order.internal_notes;

  const updateNotes = async () => {
    try {
      dispatch(setLoading(true));
      const response = await updateRequest(
        `stores/${currentStore?.store_id}/orders/${order.id}`,
        {
          customer_notes: notes.customerNotes,
          internal_notes: notes.internalNotes,
        }
      );

      if (response?.status === 200) {
        dispatch(addToast("Order is updated"));
        setShowGreenTick(true);
        fetchOrder();
        setTimeout(() => {
          setShowGreenTick(false);
        }, 3000);
      }
    } catch (error) {
      dispatch(
        addToast(error || "Something went wrong\nOrder is not updated!")
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleModalopen = () => {
   setModalOpen(true)
  }
  const handleTabChange = (newValue) => {
    const newTab = ORDER_CUSTOMER_DETAIL_TAB_LISTS[newValue].value;
    setCurrentTab(newTab);
  }

  const customerAddress = (customer) => {
    if (!customer?.addresses?.length) return "-";
    const address = getLatestAddress(customer?.addresses);
    return address?.human_readable;
  };

  useEffect(() => {
    resetFields();
  }, [user, order]);

  useEffect(() => {
    if (isCorporateCustomer) {
      setCurrentCustomer(currentTab === "corporation" ? user : order?.users_via_corporate?.[0]);
    } else {
      setCurrentCustomer(user);
    }
  }, [currentTab, user, order]);

  return (
    <Box className="max-w-[1550px] w-full flex flex-col gap-4">
      <Box className="flex md:flex-row flex-col gap-7 justify-center">
        {user &&
          <Card
          sx={{
            boxShadow: "none",
            "@media (min-width: 768px)": {
              boxShadow:
                "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)", // Shadow for md screens and above
            },
          }}
          className="md:w-1/3"
        >
          <CardContent
            sx={{
              padding: 0,
              "@media (min-width: 768px)": {
                padding: "16px",
              },
            }}
            className={`flex flex-col ${!isCorporateCustomer && "gap-2"}`}
          >
            <div className="flex justify-between">
              <Typography style={theme.typography.title}>
                Customer detail
              </Typography>
              {currentCustomer && (
                <IconButton onClick={handleModalopen} size="small">
                  <EditIcon />
                </IconButton>
              )}
            </div>
            {isCorporateCustomer ? (
              <div className="flex flex-col gap-2">
                <BasicTabs
                  list={ORDER_CUSTOMER_DETAIL_TAB_LISTS.map((tab) => tab.label)}
                  setValue={handleTabChange}
                  value={ORDER_CUSTOMER_DETAIL_TAB_LISTS.findIndex(
                    (tab) => tab.value === currentTab
                  )}
                />
                <CustomTabPanel
                  value={ORDER_CUSTOMER_DETAIL_TAB_LISTS.findIndex(
                    (tab) => tab.value === currentTab
                  )}
                  index={0}
                >
                  <CorporateDetail
                    user={user}
                    address={customerAddress(user)}
                    order={order}
                  />
                </CustomTabPanel>
                <CustomTabPanel
                  value={ORDER_CUSTOMER_DETAIL_TAB_LISTS.findIndex(
                    (tab) => tab.value === currentTab
                  )}
                  index={1}
                >
                  <CustomerDetail
                    user={order?.users_via_corporate?.[0]}
                    order={order}
                    address={customerAddress(order?.users_via_corporate?.[0])}
                    isCorporateCustomer={true}
                  />
                </CustomTabPanel>
              </div>
            ) : (
              <CustomerDetail
                user={user}
                order={order}
                address={customerAddress(user)}
              />
            )}
          </CardContent>
          </Card>
        }

        <Card
          sx={{
            boxShadow: "none",
            "@media (min-width: 768px)": {
              boxShadow:
                "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)", // Shadow for md screens and above
            },
          }}
          className={`${user ? "md:w-2/3" : "md:w-full"}`}
        >
          <CardContent
            sx={{
              padding: 0,
              "@media (min-width: 768px)": {
                padding: "16px",
              },
            }}
          >
            <Box className="flex flex-col gap-2">
              <Typography style={theme.typography.title}>
                Order Notes
              </Typography>
              <Box className="flex flex-col md:flex-row w-full gap-2">
                <Box className="flex flex-col gap-1 md:w-1/2">
                  <Typography style={theme.typography.body}>
                    Order Notes
                  </Typography>
                  <TextField
                    className="w-full"
                    id="outlined-multiline-static"
                    multiline
                    rows={isMdUp ? 1 : 8}
                    onChange={(e) =>
                      setNotes({ ...notes, customerNotes: e.target.value })
                    }
                    value={notes.customerNotes}
                    placeholder="Add notes here"
                  />
                </Box>
                <Box className="flex flex-col gap-1 md:w-1/2">
                  <Typography style={theme.typography.body}>
                    Internal Notes
                  </Typography>
                  <TextField
                    className="w-full"
                    id="outlined-multiline-static"
                    multiline
                    rows={isMdUp ? 1 : 8}
                    value={notes.internalNotes}
                    onChange={(e) =>
                      setNotes({ ...notes, internalNotes: e.target.value })
                    }
                    placeholder="Add notes here"
                  />
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box className="flex gap-5 flex-grow min-w-full self-stretch justify-end">
        <Button
          className="md:flex-grow-0 flex-grow md:w-32"
          variant="outlined"
          color="secondary"
          onClick={resetFields}
        >
          Cancel
        </Button>
        <Button
          className="md:flex-grow-0 flex-grow md:w-32"
          variant="contained"
          color="success"
          disabled={isButtonDisabled()}
          onClick={updateNotes}
        >
          Save
        </Button>
      </Box>
      <UpdateCustomerDetails
        open={isModalOpen}
        close={() => setModalOpen(false)}
        isCorporateCustomer={
          isCorporateCustomer && currentTab === "corporation"
        }
        user={currentCustomer}
        currentStore={currentStore}
        fetchOrder={fetchOrder}
      />
    </Box>
  );
};

export default OrderDetailsFooter;
