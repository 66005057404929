import {
  CUSTOMERS,
  CUSTOMERS_EMAIL_CAMPAIGNS,
  CUSTOMERS_LIST,
  DASHBOARD,
  ORDERS,
  ORDERS_OVERVIEW,
  ORDERS_ASSIGNED,
  POS,
  POS_CREATE_ORDER,
  POS_SERVICES_AND_PRODUCTS,
  SETTINGS,
  ORDERS_DETAILS,
  INDIVIDUAL_DETAILS,
  CUSTOMERS_EMAIL_CAMPAIGNS_NEW,
  CUSTOMERS_NEW,
  CONVERSATIONS,
  SCANNER_PAGE,
  DETAILS_PAGE,
  POS_CREATE_SERVICE,
  POS_CREATE_PRODUCT,
  POS_CREATE_OPTION,
  POS_CREATE_CATEGORY_TYPE,
  POS_EDIT_SERVICE,
  POS_EDIT_PRODUCT,
  POS_EDIT_OPTION,
  POS_EDIT_CATEGORY_TYPE,
  POS_EDIT_SERVICE_TYPE,
  POS_EDIT_CATEGORY,
  CUSTOMER_ROUTES,
  CORPORATION_DETAILS,
  NOTIFICATIONS,
  CORPORATE_NEW,
} from "../../constants/FrontendRoutes";

import {
  BookIcon,
  MessageIcon,
  POSIcon,
  PeopleIcon,
  SettingIcon,
  LogoutIcon,
  SpeedMeterIcon,
  SupportIcon,
} from "../../common/icons";
import {
  All_STAFF_ROLES,
  BASIC_STAFF_ROLES,
  ADMIN_ROLE,
  CUSTOMER_ROLE,
} from "../../constants";

const checkPath = (path, location) => {
  const currentPath = location.pathname.split("/");
  const checkedPath = path.split("/");
  return (
    path !== "#" &&
    checkedPath.every((seg, i) => seg === currentPath[i] || seg === ":id")
  );
};

export const getRoutes = (location, flags) => {
  return [
    {
      title: "Dashboard",
      path: DASHBOARD,
      isActive: checkPath(DASHBOARD, location),
      icon: SpeedMeterIcon,
      name: "dashboard",
      roles: BASIC_STAFF_ROLES,
      display: flags?.qcShowSalesDashboard ?? true,
    },
    {
      title: "Orders",
      class: "dashboardStep1",
      path: ORDERS_OVERVIEW,
      isActive: checkPath(ORDERS, location),
      icon: BookIcon,
      name: "orders",
      roles: All_STAFF_ROLES,
      display: true,
      childs: [
        {
          title: "Overview",
          path: ORDERS_OVERVIEW,
          isActive: checkPath(ORDERS_OVERVIEW, location),
        },
        {
          title: "Assigned Orders",
          path: ORDERS_ASSIGNED,
          isActive: checkPath(ORDERS_ASSIGNED, location),
        },
      ],
    },
    {
      title: "Messages",
      class: "dashboardStep2",
      path: CONVERSATIONS,
      isActive: checkPath(CONVERSATIONS, location),
      icon: MessageIcon,
      name: "messages",
      roles: All_STAFF_ROLES,
      display: true,
    },
    {
      title: "POS",
      class: "dashboardStep3",
      path: POS,
      isActive: checkPath(POS, location),
      icon: POSIcon,
      name: "pos",
      roles: BASIC_STAFF_ROLES,
      display: true,
      childs: [
        {
          title: "Service & Product",
          path: POS_SERVICES_AND_PRODUCTS,
          isActive: checkPath(POS_SERVICES_AND_PRODUCTS, location),
        },
        {
          title: "New Order",
          class: "dashboardStep4",
          path: POS_CREATE_ORDER,
          isActive: checkPath(POS_CREATE_ORDER, location),
        },
      ],
    },
    {
      title: "Customers",
      class: "dashboardStep5",
      path: CUSTOMERS,
      isActive: checkPath(CUSTOMERS, location),
      icon: PeopleIcon,
      name: "customers",
      roles: BASIC_STAFF_ROLES,
      display: true,
      childs: [
        {
          title: "Customer list",
          path: CUSTOMERS_LIST,
          isActive: checkPath(CUSTOMERS_LIST, location),
        },
      ],
    },
    {
      isDivider: true,
      display: true,
    },
    {
      title: "Support",
      path: "#",
      isActive: checkPath("#", location),
      icon: SupportIcon,
      name: "support",
      roles: All_STAFF_ROLES,
      display: true,
      href: "https://coblrsupport.notion.site/"
    },
    {
      title: "Settings",
      path: SETTINGS,
      isActive: checkPath(SETTINGS, location),
      icon: SettingIcon,
      name: "settings",
      roles: All_STAFF_ROLES,
      display: true,
    },
    {
      title: "Logout",
      path: "#",
      isActive: checkPath("#", location),
      icon: LogoutIcon,
      name: "logout",
      roles: All_STAFF_ROLES,
      display: true,
    },
    {
      path: ORDERS_DETAILS,
      roles: All_STAFF_ROLES,
      display: false,
    },
    {
      path: ORDERS_ASSIGNED,
      roles: All_STAFF_ROLES,
      display: false,
    },
    {
      path: CUSTOMERS_LIST,
      roles: BASIC_STAFF_ROLES,
      display: false,
    },
    {
      path: INDIVIDUAL_DETAILS,
      roles: ADMIN_ROLE,
      display: false,
    },
    {
      path: CORPORATION_DETAILS,
      roles: ADMIN_ROLE,
      display: false,
    },
    {
      path: CORPORATE_NEW,
      roles: ADMIN_ROLE,
      display: false,
    },
    {
      path: CUSTOMERS_EMAIL_CAMPAIGNS,
      roles: BASIC_STAFF_ROLES,
      display: false,
    },
    {
      path: CUSTOMERS_EMAIL_CAMPAIGNS_NEW,
      roles: BASIC_STAFF_ROLES,
      display: false,
    },
    {
      path: CUSTOMERS_NEW,
      roles: BASIC_STAFF_ROLES,
      display: false,
    },
    {
      path: POS_SERVICES_AND_PRODUCTS,
      roles: BASIC_STAFF_ROLES,
      display: false,
    },
    {
      path: POS_CREATE_ORDER,
      roles: BASIC_STAFF_ROLES,
      display: false,
    },
    {
      path: SETTINGS,
      roles: All_STAFF_ROLES,
      display: false,
    },
    {
      path: CONVERSATIONS,
      roles: All_STAFF_ROLES,
      display: false,
    },
    {
      path: SCANNER_PAGE,
      roles: BASIC_STAFF_ROLES,
      display: false,
    },
    {
      path: DETAILS_PAGE,
      roles: BASIC_STAFF_ROLES,
      display: false,
    },
    {
      path: POS_CREATE_SERVICE,
      roles: BASIC_STAFF_ROLES,
      display: false,
    },
    {
      path: POS_CREATE_PRODUCT,
      roles: BASIC_STAFF_ROLES,
      display: false,
    },
    {
      path: POS_CREATE_OPTION,
      roles: BASIC_STAFF_ROLES,
      display: false,
    },
    {
      path: POS_CREATE_CATEGORY_TYPE,
      roles: BASIC_STAFF_ROLES,
      display: false,
    },
    {
      path: POS_EDIT_SERVICE,
      roles: BASIC_STAFF_ROLES,
      display: false,
    },
    {
      path: POS_EDIT_SERVICE_TYPE,
      roles: BASIC_STAFF_ROLES,
      display: false,
    },
    {
      path: POS_EDIT_PRODUCT,
      roles: BASIC_STAFF_ROLES,
      display: false,
    },
    {
      path: POS_EDIT_OPTION,
      roles: BASIC_STAFF_ROLES,
      display: false,
    },
    {
      path: POS_EDIT_CATEGORY,
      roles: BASIC_STAFF_ROLES,
      display: false,
    },
    {
      path: POS_EDIT_CATEGORY_TYPE,
      roles: BASIC_STAFF_ROLES,
      display: false,
    },
    {
      path: NOTIFICATIONS,
      roles: All_STAFF_ROLES,
      display: false,
    },
    {
      path: CUSTOMER_ROUTES.ORDERS_OVERVIEW,
      roles: CUSTOMER_ROLE,
      display: false,
    },
    {
      path: CUSTOMER_ROUTES.INVOICES_OVERVIEW,
      roles: CUSTOMER_ROLE,
      display: false,
    },
    {
      path: CUSTOMER_ROUTES.ORDERS_DETAILS,
      roles: CUSTOMER_ROLE,
      display: false,
    },
  ];
};
