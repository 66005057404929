import { useEffect, useState } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../redux/authSlice";
import { selectCurrentStore } from "../redux/storeSlice";
import { getLDContext } from "../utils/launchDarkly";
import GlobalLoading from "../common/components/GlobalLoading";

const LDContextManager = ({ children }) => {
  const ldClient = useLDClient();
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const currentStore = useSelector(selectCurrentStore);

  useEffect(() => {
    if (!ldClient) {
      console.log("⏳ ldClient is not available yet. Skipping update.");
      return;
    }

    const updateContext = async () => {
      try {
        setIsLoading(true);
        const updatedContext = getLDContext(currentUser, currentStore);
        await ldClient.identify(updatedContext);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (currentUser && currentStore) {
      updateContext();
    }
  }, [ldClient, currentUser, currentStore]);

  return isLoading ? <GlobalLoading /> : <>{children}</>;
};

export default LDContextManager;
