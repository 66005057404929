import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import { getRequest, postRequest, updateRequest } from "../../api";
import CustomPagination from "../../components/CustomPagination";
import FileUploadModal from "../../components/Modal/FileUploadModal";
import CustomTable from "../../components/Table";
import {
  INDIVIDUAL_DETAILS,
  CUSTOMERS_NEW,
} from "../../constants/FrontendRoutes";
import { addToast } from "../../redux/toastSlice";
import { setLoading, updateProgressBar } from "../../redux/loadingSlice";
import { downloadCSV, processCSVData } from "../../utils/customerList";
import { Plus, UploadIcon } from "../../common/icons";
import { PAYMENT_STATUS, USER_ROLES } from "../../constants";
import { selectCurrentStore } from "../../redux/storeSlice";
import ActionMenu from "../Button/ActionMenu";
import UpdateActions from "../Button/UpdateActions";
import { convertDateFormat } from "../../utils";

const TABLE_COLUMNS = [
  { field: "id", label: "ID", sortable: true, width: "50px" },
  {
    field: "name",
    label: "Name",
    sortable: true,
    searchable: true,
    width: "150px",
    editable: true,
  },
  {
    field: "email",
    label: "Email",
    searchable: true,
    width: "150px",
    editable: true,
    regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    error: "",
  },
  {
    field: "phone_number",
    label: "Phone Number",
    searchable: true,
    editable: true,
    width: "150px",
    mask: "(999) 999-9999",
    regex: /^\(\d{3}\) \d{3}-\d{4}$/,
  },
  {
    field: "number_of_orders",
    label: "# of orders",
    sortable: true,
    width: "100px",
  },
  {
    field: "amount_spent",
    label: "Amount Spent",
    sortable: true,
    width: "100px",
  },
  {
    field: "signup_date",
    label: "Sign Up Date",
    sortable: true,
    width: "100px",
  },
  {
    field: "actions",
    label: "",
    width: "50px",
    component: ActionMenu,
    secondaryComponent: UpdateActions,
  },
];

const transformData = (
  data,
  store,
  navigate,
  handleEdit = () => {},
  handleCancel = () => {},
  handleConfirm = () => {}
) =>
  data?.map(
    ({
      id,
      name,
      email,
      phone_number,
      last_sign_up,
      last_amount_spent,
      created_at,
      orders,
      orders_via_corporate,
    }) => {
      const individualOrders =
        orders?.filter(
          (order) =>
            ![PAYMENT_STATUS.BILL_LATER, PAYMENT_STATUS.INVOICED].includes(
              order?.payment?.status
            ) &&
            order?.store_id == store?.id &&
            order?.status !== "quote"
        ) || [];

      const corporateOrders = orders_via_corporate?.filter(
        (order) => order?.store_id == store?.id && order?.status !== "quote"
      );

      const amountSpent = (last_amount_spent || 0) +
        (individualOrders?.reduce((sum, order) => sum + order?.net_total, 0) ||
        0);

      return {
        id,
        name,
        email,
        phone_number,
        number_of_orders: [...individualOrders, ...corporateOrders].length || 0,
        amount_spent: `$${amountSpent?.toFixed(2)}`,
        signup_date: convertDateFormat(
          last_sign_up ? last_sign_up : created_at
        ),
        link: INDIVIDUAL_DETAILS.replace(":id", id),
        actionsProps: {
          listOfActions: [
            {
              label: "View details",
              handler: () => navigate(INDIVIDUAL_DETAILS.replace(":id", id)),
            },
            { label: "Edit", handler: () => handleEdit(id) },
          ],
        },
        secondaryActionProps: {
          onCancel: () => handleCancel(id),
          onConfirm: (obj, row) => handleConfirm({ id, obj, row }),
        },
        editMode: false,
      };
    }
  );

const IndividualCustomerList = () => {
  const [rows, setRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentStore = useSelector(selectCurrentStore);

  const [paginationMeta, setPaginationMeta] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    if (currentStore) fetchStoreCustomers();
  }, [currentStore, currentPage, perPage]);

  const fetchStoreCustomers = async (searchInput = "") => {
    const query =
      searchInput.trim() !== ""
        ? { name_or_email_or_phone_number_cont: searchInput }
        : {};

    try {
      const {
        data,
        meta: { pagination },
      } = await getRequest(
        `stores/${currentStore?.store_id}/users`,
        {
          q: query,
          filters: { role: [USER_ROLES.CUSTOMER] },
          page: currentPage,
          per_page: perPage,
        },
        "orders.payment,orders_via_corporate.payment&fields=user.id,user.name,user.email,user.phone_number,user.last_sign_up,user.last_amount_spent,user.created_at,order.status,order.net_total,order.store_id,order.under_warranty"
      );

      if (data?.length > 0) {
        setPaginationMeta(pagination);
        setRows(
          transformData(
            data,
            currentStore?.store,
            navigate,
            handleEdit,
            handleCancel,
            handleConfirm
          )
        );
      } else {
        dispatch(addToast("Customers not Found", { appearance: "error" }));
      }
    } catch (error) {
      dispatch(addToast(`Error: ${error.message}`, { appearance: "error" }));
    }
  };

  const handleFileUpload = (file) => {
    const reader = new FileReader();

    reader.onload = async (e) => {
      dispatch(setLoading(true));
      dispatch(updateProgressBar({ display: true, value: 0 }));

      const csvData = e.target.result;
      const customers = processCSVData(csvData);

      const batches = [];
      const batchSize = 50;
      const totalBatches = Math.ceil(customers?.length / batchSize);
      const allFailedUsers = [];
      let processedBatches = 0;

      for (let i = 0; i < customers?.length; i += batchSize) {
        batches.push(customers.slice(i, i + batchSize));
      }

      try {
        for (const batch of batches) {
          const {
            data: { failed_users },
          } = await postRequest(
            `stores/${currentStore?.store_id}/users/batch_create`,
            { users: batch }
          );

          allFailedUsers.push(...failed_users);

          processedBatches++;
          const progressValue = Math.round(
            (processedBatches / totalBatches) * 100
          );
          dispatch(updateProgressBar({ display: true, value: progressValue }));
        }

        dispatch(
          updateProgressBar({
            successMessage: `${
              customers.length - allFailedUsers.length
            } out of ${customers.length} User(s) successfully created. `,
          })
        );
      } catch (error) {
        dispatch(
          addToast(`Error adding customers: ${error?.message || error}`, {
            appearance: "error",
          })
        );
      } finally {
        if (allFailedUsers?.length > 0) {
          const csvHeader = "Full Name,Email,Phone Number,Errors\n";
          const csvContent = allFailedUsers
            .map((user) => {
              const errors = user.errors.join(" | ");
              return `${user?.name || ""},${user?.email || ""},${
                user?.phone_number || ""
              },"${errors}"`;
            })
            .join("\n");

          const csvString = csvHeader + csvContent;
          downloadCSV(csvString, "conflict_report.csv");
        }
      }
    };

    reader.readAsText(file);
  };

  const handleEdit = (id) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, editMode: true } : row))
    );
  };

  const handleCancel = (id) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, editMode: false } : row))
    );
  };

  const handleConfirm = async ({ id, obj, row }) => {
    try {
      const { name, email, phone_number } = obj;
      const response = await updateRequest(
        `stores/${currentStore?.store_id}/users/${id}`,
        {
          user: {
            name,
            email,
            phone_number,
          },
        }
      );

      if (response?.status === 200) {
        setRows((prevRows) =>
          prevRows.map((row) =>
            row.id === id ? { ...row, ...obj, editMode: false } : row
          )
        );

        dispatch(addToast("Updated successfully"));
      }
    } catch (error) {
      dispatch(addToast(error || "Failed to update user details"));
    }
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <div className="flex flex-col justify-between">
      <CustomTable
        columns={TABLE_COLUMNS}
        rows={rows}
        sortable
        searchable
        searchCallback={fetchStoreCustomers}
        setCurrentPage={setCurrentPage}
        actions={
          <>
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              startIcon={<UploadIcon />}
              sx={{
                color: "black",
                font: "Questrial",
                fontSize: "1rem",
                borderRadius: "0.5rem",
                borderColor: "rgb(214, 211, 209)",
                textTransform: "none",
                "&:hover": {
                  borderColor: "black",
                  backgroundColor: "transparent",
                },
              }}
              onClick={handleOpenModal}
            >
              Import Data
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Plus />}
              onClick={() => navigate(CUSTOMERS_NEW)}
            >
              Add Customer
            </Button>
          </>
        }
      />
      <CustomPagination
        paginationMeta={paginationMeta}
        setPerPage={setPerPage}
        setCurrentPage={setCurrentPage}
      />
      <FileUploadModal
        open={modalOpen}
        onClose={handleCloseModal}
        onFileUpload={handleFileUpload}
      />
    </div>
  );
};

export default IndividualCustomerList;
